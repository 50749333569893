import React from "react";
import { CSVLink } from "react-csv";
import { FiUploadCloud } from "react-icons/fi";
import ButtonComp from "../Ui/Button";

export default function CsvDownload({
  filename = "",
  Data = [],
  background = "#FAFAFA",
  color = "#2B7DC0",
  ...props
}) {
  return (
    <CSVLink filename={filename} data={Data}>
      <ButtonComp
        btnClassName={
          "border-[#4F008A] w-[100%] border-[1px] rounded-pill flex-1 px-3 px-lg-4 py-2 py-lg-2"
        }
        text={
          <div className="flex gap-1 items-center text-[14px] text-black">
            {/* <FiUploadCloud size={20} style={{ transform: "rotate(180deg)" }} />{" "} */}
            <img src="/svgs/downloadIcon.svg" alt="" />
            <div>Report</div>
          </div>
        }
      />
    </CSVLink>
  );
}

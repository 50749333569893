import React from "react";

export default function SelectComp({
  containerClassName,
  label,
  labelClassName,
  wrapperClassName,
  placeholder,
  options = [],
  error,
  inputName,
  inputValue,
  defaultSelect,
  disable,
  ...props
}) {
  // console.log(options, "categories");
  return (
    <div className={wrapperClassName}>
      {label && (
        <div className={`mb-[10px] font-medium ${labelClassName}`}>{label}</div>
      )}
      <select
        className={`border-[1px] border-[#EDE6F3] rounded-md px-2 py-1 h-[50px] flex gap-1 items-center w-full ${containerClassName}`}
        {...props}
      >
        <option>{defaultSelect || "Select..."}</option>

        {options?.map((item, i) => (
          <option
            key={i}
            value={inputName ? item[inputValue] || item[inputName] : item?.id}
          >
            {inputName ? item[inputName] : item?.category || item?.name}
          </option>
        ))}
      </select>
      {error && (
        <div className="text-danger text-start ps-1">
          {label} {error}
        </div>
      )}
    </div>
  );
}

import React, { useRef, useState } from "react";
import ButtonComp from "../../Ui/Button";
import { t } from "i18next";
import SelectComp from "../../Ui/SelectComp";
import SelectCompI from "../../Ui/SelectCompI";
import { toast } from "react-toastify";

import { useSelectCurrencyMutation } from "../../../store/Transactions/transactionsApi";
import { storage } from "../../../utils/helper";
import { useUpdateUserProfileMutation } from "../../../store/User/userApi";
import { uploadFileToS3 } from "../Product/helper";
import { Spinner } from "react-bootstrap";
const Profile_information = ({ userData, setUserData }) => {
  const [changeCurrency, { isLoading }] = useSelectCurrencyMutation();
  const [currency, setCurrency] = useState();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  const fileInputRef = useRef(null);

  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const currencyList = [
    {
      name: "NGN",
      label: "Nigeria Naira ",
    },
    {
      name: "Cedi",
      label: "Ghana (Cedi)",
    },
    {
      name: "KES",
      label: "Kenya (Kenya Shilling)",
    },
    {
      name: "CFA",
      label: "Senegal (CFA Francs)",
    },
    {
      name: "UGX",
      label: "Uganda (Uganda Shilling)",
    },
    {
      name: "TZS",
      label: "Tanzania (Tanzania Shilling)",
    },
    {
      name: "CFA",
      label: "Niger CFA Francs",
    },
    {
      name: "CFA",
      label: "Benin CFA Francs",
    },
    {
      name: "ZAR",
      label: "South Africa",
    },
    {
      name: "USD",
      label: "USA (Dollar)",
    },
    {
      name: "CAD",
      label: "Canada (Canadian Dollar)",
    },
  ];

  const [
    updateUserProfile,
    { isLoading: updateUserProfileLoading, isSuccess, refetch },
  ] = useUpdateUserProfileMutation();

  const handleInputOnchange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const currencySymbolII =
    storage?.localStorage?.get("phramasrveCuurency") ||
    storage.localStorage.get("user_details_profile")?.user?.currency;

  // console.log(userData, "userData");
  const handleChangeCurrency = async () => {
    if (!currency) {
      return toast.error("Please Select Currency");
    }
    const responses = await changeCurrency({
      currency: currency,
    });
    if (responses?.data) {
      toast.success(responses?.data?.message);
      storage.localStorage.set(`phramasrveCuurency`, currency);
      setCurrency();
    }
    if (responses?.error) {
      toast.error(responses?.error?.message);
    }
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setFile(imageFile);
      setFileUrl(URL.createObjectURL(imageFile));
    }
  };

  const handleUploadImage = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error("No File Selected");
      setLoading(false);
    } else {
      try {
        setLoading(true);
        const bucketName = process.env.REACT_APP_BUCKET_NAME;
        const folderName = userData?.companyId; // Optional: Set a folder name in S3
        const fileUrl = await uploadFileToS3(file, bucketName, folderName);

        setLoading(false);
        if (fileUrl || !loading) {
          setUploadedFile(fileUrl);
          toast.success("File Uploaded Successfully");
        }
      } catch (error) {
        console.error("Error uploading file:", error?.message);
      }
    }
  };

  const handleProfileUpdate = async () => {
    const userValue = {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      phoneNumber: userData?.phoneNumber,
      fullAddress: userData?.fullAddress,
      currency,
      avatar: uploadedFile || userData?.avatar,
    };

    try {
      const res = await updateUserProfile({ body: userValue });
      if (res?.data) {
        toast.success("Profile updated successfully");
        setUserData(res?.data?.data);
      } else {
        toast.error(res?.error?.data?.message);
      }
    } catch (error) {
      // toast.error(error?.message);
      console.log(error);
    }
  };

  return (
    <div className="pb-[30vh]">
      <div className="flex flex-col  md:gap-[2rem] md:flex-row md:justify-between justify-start items-center">
        <div className="mt-8 w-full">
          <h1 className="text-lg font-bold">{t(`lang.PI`)}</h1>
          <p className="text-sm"> {t(`lang.UYPPDH`)}</p>
        </div>

        <div className="flex items-center md:justify-end justify-start gap-[1rem] w-full">
          <ButtonComp
            text={t(`lang.D`)}
            btnClassName={
              "px-5 border-[1px] md:text-[1rem] text-[.8rem] border-[#DE5A5A] py-2 rounded-pill"
            }
          />
          <ButtonComp
            onClick={handleProfileUpdate}
            loader={updateUserProfileLoading}
            text={t(`lang.SC`)}
            btnClassName={
              "bg-[#3DA84A] text-white py-2 md:text-[1rem] text-[.8rem] rounded-pill w-[10rem]"
            }
          />
        </div>
      </div>

      <div className="md:flex mt-10">
        <div className="md:w-[50%] w-full md:pr-4">
          <form className="space-y-4 md:space-y-6" action="#">
            <div className="">
              <label
                for="name"
                className="block mb-[1rem] text-sm font-bold text-[#4F008A] capitalize"
              >
                {t(`lang.PPP`)}
              </label>
              <div className="flex items-center justify-start gap-[1.25rem]">
                <img
                  src={fileUrl || userData?.avatar || "./icon/user.png"}
                  alt=""
                  className="h-10 w-10 rounded-full"
                />
                <div className="item_container">
                  <input
                    ref={fileInputRef}
                    accept="image/*"
                    type="file"
                    name="avatar"
                    id=""
                    className="hidden"
                    onChange={handleImageChange}
                  />

                  <ButtonComp
                    loader={loading}
                    onClick={file ? handleUploadImage : handleButtonClick}
                    text={file ? "Upload Photo" : "Change Photo"}
                    btnClassName={
                      "py-[10px] bg-[#F6F2F9] !text-[.8rem] text-[#282728] font-medium px-[1rem]"
                    }
                  />
                </div>
                <p className="text-sm mt-2"> {t(`lang.jpeg`)} </p>
              </div>
            </div>
            <div className="flex md:space-x-5 md:flex-row flex-col">
              <div className="w-full">
                <label
                  for="name"
                  className="block mb-2 text-sm font-bold text-[#4F008A]"
                >
                  {t(`lang.FN`)}
                </label>
                <input
                  placeholder="Olakunbi"
                  className="border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2"
                  required={true}
                  defaultValue={userData?.firstName}
                  onChange={handleInputOnchange}
                  name="firstName"
                />
              </div>
              <div className="w-full">
                <label
                  for="name"
                  className="block mb-2 text-sm font-bold text-[#4F008A]"
                >
                  {t(`lang.LN`)}
                </label>
                <input
                  placeholder="Olabode"
                  className=" border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2"
                  required=""
                  defaultValue={userData?.lastName}
                  onChange={handleInputOnchange}
                  name="lastName"
                />
              </div>
            </div>

            <div>
              <label
                for="email"
                className="block mb-2 text-sm font-bold text-[#4F008A]"
              >
                {t(`lang.EA`)}
              </label>
              <input
                type="email"
                name="email"
                id="password"
                placeholder="olakunbiolabode01@gmail.com"
                className=" border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2"
                required=""
                defaultValue={userData?.email}
                onChange={handleInputOnchange}
              />
            </div>
          </form>
        </div>

        <div className="h-[20rem] w-[.01rem] md:block hidden bg-[#4F008A]/10 mx-[1.5rem]"></div>

        <div className="md:w-[50%] md:pl-8 w-full">
          <div className="md:mt-0 mt-[1rem]">
            <label
              for="phoneNumber"
              className="block  text-sm font-bold text-[#4F008A] "
            >
              {t(`lang.PNN`)}
            </label>
            <div className="input_container mt-[1rem] h-[2.7rem] flex items-center justify-center w-full border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600">
              <div className="number border-r-[1px] px-[1.15rem] font-[500] border-gray-300">
                +234
              </div>
              <input
                type="telephone"
                name="phoneNumber"
                id="contact"
                placeholder="909393833"
                className="sm:text-sm text-[16px] mb-[.5rem] w-full p-2 mt-2"
                required=""
                defaultValue={userData?.phoneNumber}
                onChange={handleInputOnchange}
              />
            </div>
          </div>

          <div className="mt-[2rem]">
            <label
              for="address"
              className="block mb-2 text-sm font-bold text-[#4F008A]"
            >
              {t(`lang.HA`)}
            </label>
            <input
              type="text"
              name="fullAddress"
              id="address"
              placeholder="No 56 Yitulo Avenu Lagos"
              className=" border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2"
              required=""
              defaultValue={userData?.fullAddress}
              onChange={handleInputOnchange}
            />
          </div>

          <div className="mt-[2rem]">
            <SelectComp
              label={"Change currency"}
              labelClassName={"block mb-2 text-sm font-bold text-[#4F008A]"}
              options={currencyList}
              inputName="label"
              inputValue={"name"}
              value={
                currency ||
                currencyList?.find((item, i) => item?.name == currencySymbolII)
                  ?.name
              }
              onChange={(e) => setCurrency(e.target.value)}
            />

            <ButtonComp
              onClick={handleChangeCurrency}
              loader={isLoading}
              text="Change Currency"
              btnClassName={
                "bg-[#3DA84A] ml-auto mt-4 text-white py-2 md:text-[1rem] text-[.8rem] rounded-pill w-[12rem]"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// - Nigeria = NGN (Naira)
// - Ghana = Cedi (Cedi)
// - Kenya = KSE (Kenya Shilling)
// - Senegal = CFA (CFA Francs)
// - Uganda = UGX (Uganda Shilling)
// - Tanzania = TZS (Tanzania Shilling)
// - Niger = CFA Francs
// - Benin = CFA Francs
// - South Africa = ZAR (Rand)
// - USA = USD (Dollar)
// - Canada = CAD (Canadian Dollar)

export default Profile_information;

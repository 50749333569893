import React, { useRef, useState } from "react";
import ButtonComp from "../../Ui/Button";
import { t } from "i18next";
import SelectComp from "../../Ui/SelectComp";
import Country from "../../../country.json";
import { uploadFileToS3 } from "../Product/helper";
import { toast } from "react-toastify";
import { useUpdateUserCompanyMutation } from "../../../store/User/userApi";

const Company_Profile = ({ setUserData, userData }) => {
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [updateUserCompany, { isLoading, isSuccess }] =
    useUpdateUserCompanyMutation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      company: {
        ...prevData.company,
        [name]: value,
      },
    }));
  };

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setUserData((prevData) => ({
      ...prevData,
      company: {
        ...prevData.company,
        country: e.target.value,
        state: "",
      },
    }));
  };

  const selectedCountryData = Country.find(
    (item) => item.name === selectedCountry
  );

  const fileInputRef = useRef(null);

  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setFile(imageFile);
      setFileUrl(URL.createObjectURL(imageFile));
    }
  };

  const handleUploadImage = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error("No File Selected");
      setLoading(false);
    } else {
      try {
        setLoading(true);
        const bucketName = process.env.REACT_APP_BUCKET_NAME;
        const folderName = userData?.companyId; // Optional: Set a folder name in S3
        const fileUrl = await uploadFileToS3(file, bucketName, folderName);

        setLoading(false);
        if (fileUrl || !loading) {
          setUploadedFile(fileUrl);
          toast.success("File Uploaded Successfully");
        }
      } catch (error) {
        console.error("Error uploading file:", error?.message);
      }
    }
  };

  const handleUpdateCompany = async () => {
    const value = {
      name: userData?.company?.name,
      email: userData?.company?.email,
      phoneNumber: userData?.company?.phoneNumber,
      address: userData?.company?.address,
      logo: uploadedFile || userData?.company?.logo,
      currency: userData?.currency,
      country: userData?.company?.country,
      state: userData?.company?.state,
    };

    try {
      const res = await updateUserCompany({ body: value });
      if (res?.data) {
        toast.success("Profile updated successfully");
        setUserData(res?.data?.data);
      } else {
        toast.error(res?.error?.data?.message);
      }
    } catch (error) {
      // toast.error(error?.message);
      console.log(error);
    }
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="mt-8 w-full">
          <h1 className="text-lg font-bold">{t("lang.CP")}</h1>
          <p className="text-sm">{t("lang.UYCPDH")}</p>
        </div>

        <div className="flex items-center md:justify-end justify-start gap-[1rem] w-full">
          <ButtonComp
            text={t("lang.D")}
            btnClassName="px-5 border-[1px] border-[#DE5A5A] py-2 rounded-pill"
          />
          <ButtonComp
            onClick={handleUpdateCompany}
            loader={isLoading}
            text={t("lang.SC")}
            btnClassName="bg-[#3DA84A] w-[8rem] !text-[.8rem] py-[.8rem] text-white rounded-pill"
          />
        </div>
      </div>

      <div className="md:flex my-10">
        <div className="md:w-[50%] w-full md:pr-4">
          <form className="space-y-4 md:space-y-6" action="#">
            <div>
              <label
                htmlFor="logo"
                className="block mb-2 text-sm font-bold text-[#4F008A]"
              >
                {t("lang.CL")}
              </label>
              <div className="flex flex-row items-center justify-start gap-[1.5rem]">
                <img
                  src={fileUrl || userData?.company?.logo || "./icon/user.png"}
                  className="h-10 w-10 rounded-full"
                  alt="Company Logo"
                />
                <div className="item_container">
                  <input
                    ref={fileInputRef}
                    accept="image/*"
                    type="file"
                    name="avatar"
                    id=""
                    className="hidden"
                    onChange={handleImageChange}
                  />

                  <ButtonComp
                    loader={loading}
                    onClick={file ? handleUploadImage : handleButtonClick}
                    text={file ? "Upload Photo" : "Change Photo"}
                    btnClassName={
                      "py-[10px] bg-[#F6F2F9] text-[#282728] font-medium px-[1rem]"
                    }
                  />
                </div>
                <p className="text-sm mt-2">{t("lang.jpeg")}</p>
              </div>
            </div>
            <div>
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-bold text-[#4F008A]"
              >
                {t("lang.CN")}
              </label>
              <input
                name="name"
                placeholder="PharmaServe LTD"
                className="border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2"
                required
                value={userData?.company?.name}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-bold text-[#4F008A]"
              >
                Company Email
              </label>
              <input
                type="email"
                name="email"
                placeholder="PharmaServe LTD"
                className="border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2"
                required
                value={userData?.company?.email}
                onChange={handleInputChange}
              />
            </div>
          </form>
        </div>

        <div className="h-[20rem] w-[.01rem] md:block hidden bg-[#4F008A]/10 mx-[1.5rem]"></div>

        <div className="md:w-[50%] md:pl-8 w-full">
          <div className="md:mt-0 mt-[1rem]">
            <label
              htmlFor="phoneNumber"
              className="block text-sm font-bold text-[#4F008A]"
            >
              {t("lang.PNN")}
            </label>
            <div className="input_container mt-[1rem] h-[2.7rem] flex items-center justify-center w-full border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600">
              <div className="number border-r-[1px] px-[1.15rem] font-[500] border-gray-300">
                +234
              </div>
              <input
                type="contact"
                name="contactPhoneNo"
                placeholder="909393833"
                className="sm:text-sm text-[16px] mb-[.5rem] w-full p-2 mt-2"
                required
                value={userData?.company?.contactPhoneNo}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex items-center md:justify-between justify-center md:flex-row flex-col gap-[1.5rem] mt-[2rem]">
            <div className="item_container w-full">
              <label
                for="country"
                className="font-[500] block mb-[.6rem] text-[#4F008A]"
              >
                Country
              </label>
              <select
                name="country"
                value={userData?.company?.country || ""}
                onChange={handleCountryChange}
                className="border border-black w-full rounded-[8px] h-[3rem] px-[1rem]"
              >
                {Country.map((item) => (
                  <option value={item.name} key={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="item_container w-full">
              <label
                for="state"
                className="font-[500] block mb-[.6rem] text-[#4F008A]"
              >
                State
              </label>
              <select
                name="state"
                onChange={handleInputChange}
                className="border border-black w-full rounded-[8px] h-[3rem] px-[1rem]"
                value={userData?.company?.state || ""}
              >
                <option value="">Select...</option>
                {selectedCountryData?.state?.map((item) => (
                  <option value={item.name} key={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mt-4">
            <label className="block mb-1 text-sm font-bold text-[#4F008A]">
              {t("lang.CA")}
            </label>
            <input
              name="address"
              placeholder="PharmaServe LTD"
              className="border border-gray-300 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-2"
              required
              value={userData?.company?.address}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company_Profile;

import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  useNotificationListQuery,
  useReadNotificationIIMutation,
} from "../../../store/DashBoard/DashBoardApi";
import moment from "moment";

const Notifications = () => {
  const { data, isLoading, isSuccess } = useNotificationListQuery();
  const [
    readNotificationII,
    { isLoading: notificationsLoading, isSuccess: notificationsSuccess },
  ] = useReadNotificationIIMutation();

  const [read, setRead] = useState(true);

  moment.updateLocale("en", {
    calendar: {
      sameDay: "[Today at] LT",
      nextDay: "[Tomorrow at] LT",
      nextWeek: "dddd [at] LT",
      lastDay: "[Yesterday at] LT",
      lastWeek: "[Last] dddd [at] LT",
      sameElse: "L",
    },
  });

  console.log(data?.data?.notifications);

  const handleRead = (itemId) => {
    readNotificationII({ id: itemId });
  };

  const [filterArray, setFilterArray] = useState(data?.data?.notifications);

  const handleFilter = (e) => {
    if (e.target.value === "Unread") {
      setFilterArray(filterArray.filter((item) => !item?.isRead));
    } else {
      setFilterArray(data?.data?.notifications);
    }
  };

  const markAllAsRead = () => {
    const all = filterArray.map((item) => {
      return { ...item, isRead: true };
    });

    setFilterArray(all);
  };

  if (isLoading) {
    return (
      <div className="item_container flex items-center justify-center">
        <Spinner size="md" />
      </div>
    );
  }

  return (
    <div className="item_container">
      <div className="page_title w-full my-[2rem] flex md:items-center items-start justify-center md:flex-row md:gap-0 gap-[1rem] flex-col md:justify-between">
        <div className="title flex items-center justify-center gap-2">
          <span className="block font-[500] text-[1.1rem] text-black">
            Notifications
          </span>

          <div className="">
            <select
              name=""
              id=""
              className="border-none outline-none w-[4.5rem]"
              onChange={handleFilter}
            >
              <option value="All">All</option>
              <option value="Unread">Unread</option>
            </select>
          </div>
        </div>

        <div className="button_containe">
          <button
            onClick={markAllAsRead}
            className="outline-none border-none flex items-center gap-[1rem]"
          >
            <small className="block font-[500] text-[1rem]">
              Mark all as read
            </small>
            <img src="/svgs/markAll.svg" alt="" />
          </button>
        </div>
      </div>
      {filterArray.length === 0 ? (
        <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
          <img
            src="/svgs/noSchedule.svg"
            alt=""
            className="w-[5rem] h-[5rem]"
          />
          <span className="block">No Notifications Yet</span>
        </div>
      ) : (
        <div className="item_container flex flex-col gap-[1rem]">
          {filterArray
            ?.slice()
            .reverse()
            .map((item) => (
              <div
                onClick={() => handleRead(item?.id)}
                className={`notifications_card md:px-[1.4rem] px-[.6rem] py-[1.5rem] flex items-start justify-start md:gap-[1rem] gap-[.5rem] w-full rounded-[6px] min-h-[7rem] ${
                  item?.isRead ? "bg-white" : "bg-[#FCFAFF]"
                }`}
                key={item?.id}
              >
                {!item?.isRead && (
                  <div className="ball_container flex items-center justify-center">
                    <div className="item_container h-[.8rem] w-[.8rem] mt-[.45rem] rounded-full bg-[#A162F7]"></div>
                  </div>
                )}
                <div className="text_container">
                  <span className="content font-[500] text-[1.1rem] text-black block">
                    {item?.content}
                  </span>
                  <small className="text-[#A5ACB8] block font-[500] text-[.9rem]">
                    {moment(item?.createdAt).calendar()}
                  </small>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Notifications;

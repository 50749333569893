import React, { useState } from "react";
import AllRequests from "./tabs/AllRequests";

const StockRequestMain = () => {
  const [currentTab, setCurrentTab] = useState("All Requests");

  const data = [
    {
      icon: <img src="/svgs/total-request.svg" alt="" />,
      type: "Total Requests",
      number: 1580,
      percentage: 5,
    },
    {
      icon: <img src="/svgs/pending-requests.svg" alt="" />,
      type: "Pending Requests",
      number: 580,
      percentage: 5,
    },
    {
      icon: <img src="/svgs/approved-requests.svg" alt="" />,
      type: "Approved Requests",
      number: 1000,
      percentage: 5,
    },
  ];

  const tabs = [
    {
      name: "All Requests",
      component: <AllRequests />,
    },
    {
      name: "Pending Requests",
      component: "",
    },
    {
      name: "Approved Requests",
      component: "",
    },
  ];

  return (
    <div className="page_container">
      {/* first-section */}
      <section className="page_title flex mb-[2rem] md:items-center w-full gap-[1rem] items-start justify-start md:justify-between md:flex-row flex-col">
        <div className="title_flex flex items-center justify-center gap-[.8rem]">
          <h1 className="font-bold md:text-[2rem] text-[1.35rem]">
            Stock Requests
          </h1>
          <img src="/svgs/stock.svg" alt="" />
        </div>
        <button className="download_button flex items-center gap-[1.5rem] justify-center w-[14rem] h-[3rem] border border-gray-200 rounded-[6px]">
          <img src="/svgs/download-black.svg" alt="" />
          <span className="block font-[500] text-[1rem] text-black">
            Download Report
          </span>
        </button>
      </section>

      {/* second section */}

      <section className="card_container flex items-center justify-center gap-[1.5rem] md:flex-row flex-col">
        {data.map((item) => (
          <div
            key={item.type}
            className="item_card w-full border p-[1.55rem] flex border-gray-400 rounded-[6px] min-h-[8rem] flex-col gap-[.5rem]"
          >
            <div className="total_requests flex items-start justify-start gap-[.5rem]">
              {item.icon}
              <span className="block font-[500] text-[1.15rem]">
                {item.type}
              </span>
            </div>
            <div className="number_container">
              <span className="block font-semibold md:text-[2rem]">
                {item.number}
              </span>
            </div>
            <div className="analysis_container flex items-start justify-start gap-[1rem]">
              <div className="flex items-center justify-center">
                <img src="/svgs/stock-green.svg" alt="" />
                <span className="block">{`${item.percentage}%`}</span>
              </div>
              <span className="block text-[#ABABAB]"> in the last 1 month</span>
            </div>
          </div>
        ))}
      </section>

      {/* huge flex before table */}

      <div className="overflow-x-auto my-[3rem] no-scrollbar w-full">
        <section className="item_container flex flex-col md:flex-row items-center justify-between gap-[1rem] min-w-max">
          <div className="tab_section flex overflow-x-auto border border-[#4F008A]/5 rounded-[8px] items-center justify-start h-[3rem] md:min-w-[30.66rem] w-full">
            {tabs.map((item) => (
              <div
                onClick={() => setCurrentTab(item.name)}
                className={`items_container text-[#555555] font-[500] md:text-[1.1rem] px-[1rem] flex items-center justify-center h-full ${
                  currentTab === item.name
                    ? "bg-[#4F008A]/10 text-[#4F008A]"
                    : ""
                }`}
                key={item.name}
              >
                {item.name}
              </div>
            ))}
          </div>

          <div className="input_container w-full border py-[.5rem] border-gray-300 rounded-[7px] flex items-center justify-center h-[3rem]">
            <div className="icon_section w-[15%] flex items-center justify-center h-full border-r-[1px] border-gray-300">
              <img src="/svgs/search.svg" alt="" />
            </div>
            <div className="input_section w-full h-full">
              <input
                type="text"
                className="border-none w-full h-full text-[16px] px-[1rem] outline-none placeholder:text-[#555555]"
                placeholder="Search for debt by ID, Name, Date, Category"
              />
            </div>
          </div>

          <div className="category_container flex items-center justify-center p-[.8rem] gap-[.9rem] border border-gray-300 rounded-[6px] h-[3rem] md:w-[30%] w-full">
            <span className="title block font-[500] text-[1.1rem]">
              Categories:
            </span>
            <select
              name="categories"
              id=""
              className="border-none outline-none font-[500] text-[1.1rem]"
            >
              <option value="All">All</option>
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
            </select>
          </div>
        </section>
      </div>

      <section className="tabs_section">
        {
          <div className="pages_section">
            {tabs.map((item) => {
              if (currentTab === item.name) {
                return item.component;
              }
            })}
          </div>
        }
      </section>
    </div>
  );
};

export default StockRequestMain;

import React, { useState, useEffect } from "react";
import { useGetScheduleQuery } from "../../../../store/Customer/CustomerApi";
import { currentDate, firstDateOfTheMonth } from "../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { TableComp } from "../../../Common/TableComp";
import { ScheduleColumns, ScheduleColumnsII } from "../../Schedule/Data";

const Schedule = ({ id }) => {
  const [startDate, setStartDate] = useState(firstDateOfTheMonth());
  const [endDate, setEndDate] = useState(currentDate);
  const [repSchedule, setRepSchedule] = useState([0]);

  const {
    data: getScheduleData,
    isLoading,
    isSuccess,
    isFetching: getScheduleReFreshing,
    refetch,
  } = useGetScheduleQuery(
    {
      startDate,
      endDate,
    },
    {
      skip: !startDate || !endDate,
      refetchOnMountOrArgChange: false,

      // skipToken: startDate||startDate
    }
  );

  const realData = getScheduleData?.data.filter(
    (item) => item.userId === Number(id)
  );

  console.log(realData, "Data");

  const navigate = useNavigate();

  return (
    <div className="schedule">
      {isLoading && realData?.length === 0 ? (
        <section className="text-center font-serif text-[1.35rem] font-[500] justify-center items-center">
          <Spinner size="md" />
        </section>
      ) : isSuccess && realData?.length === 0 ? (
        <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
          <img
            src="/svgs/noSchedule.svg"
            alt=""
            className="w-[5rem] h-[5rem]"
          />
          <span className="block">No Schedule Yet</span>
        </div>
      ) : (
        // realData
        //   ?.slice()
        //   .reverse()
        //   .map((item) => (
        //     <div
        //       className="item_container flex items-center justify-start mb-[2rem] gap-[1rem]"
        //       key={item.id}
        //     >
        //       <div className="image_container w-[3rem] h-[3rem] p-[.7rem] rounded-full bg-[#4F008A]/10 flex items-center justify-center">
        //         <img
        //           src={`/svgs/${
        //             item?.visitationReason === "Orders"
        //               ? "delivery"
        //               : item?.visitationReason === "Payment"
        //               ? "payment"
        //               : "visit"
        //           }.svg`}
        //           alt=""
        //           className="w-[2rem] h-[2rem]"
        //         />
        //       </div>
        //       <div className="text_container  w-full flex flex-col items-start justify-between h-full">
        //         <small className="block text-[.75rem] text-gray-400">
        //           {moment(item?.createdAt).format("h a - MMMM Do YYYY")}
        //         </small>
        //         <span className="block text-[.8rem]">
        //           {item?.visitationReason === "Orders"
        //             ? `Drug order by ${item?.customer?.contactPersonName} with status of ${item.status}`
        //             : item?.visitationReason === "Payment"
        //             ? `Payment to ${item?.customer?.contactPersonName} of ${item.customer?.businessName} at ${item?.customer?.address}`
        //             : `Lead visit of ${item.customer?.businessName} at ${item?.customer?.address}`}
        //         </span>
        //       </div>
        //     </div>
        //   ))

        <div className="item_container">
          <TableComp
            loader={isLoading}
            Data={realData?.slice().reverse()}
            columnsComp={ScheduleColumnsII(
              realData?.data?.length > 0 && [...realData?.data]?.reverse()
            )}
            pagination
          />
        </div>
      )}
    </div>
  );
};

export default Schedule;

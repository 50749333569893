import React, { useEffect, useState } from "react";
import { TableComp } from "../../Common/TableComp";
import { FieldRepColumns } from "./Data";
import { useFieldRepsQuery } from "../../../store/DashBoard/DashBoardApi";
import OffCanvasComp from "../../../Components/Ui/OffCanvas.jsx";
import AddFieldRep from "./modules/addFieldRep";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../Ui/Button";
import { t } from "i18next";
import { Spinner } from "react-bootstrap";
const FieldRep = () => {
  const [showCavans, setShowCavans] = useState(false);
  const [getFieldRep, setFieldRep] = useState([]);
  const [edit, setEdit] = useState(true);
  const { data, isLoading, isSuccess, refetch } = useFieldRepsQuery();

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      setFieldRep(data?.data?.users);
    }
  }, [data?.data, isSuccess]);

  const tableDropDown = [
    {
      name: "View Rep Details",
      action: (row) => {
        navigate(`/field-rep/${row?.id}`);
        // setShowCavans(true);
      },
    },
  ];

  console.log(getFieldRep, "getFieldRep");
  return (
    <div className="pt-[10px]">
      <div className="flex justify-between md:flex-row flex-col items-center mb-5 w-full">
        <div className="w-full">
          <div className="text-[20px] text-[#333333] font-semibold">
            {t(`lang.FieldRep`)}
          </div>
          <p className="text-[#555555] font-medium">{t(`lang.ANFRAUT`)} </p>
        </div>
        <div className="flex items-center md:justify-end justify-start gap-3 w-full">
          <ButtonComp
            text={t(`lang.D`)}
            btnClassName={
              "border-[#D63434] border-[1px] py-[8px] px-[30px] rounded-full"
            }
          />
          <ButtonComp
            onClick={() => setShowCavans(true)}
            btnClassName={
              "border-[#3DA84A] bg-[#3DA84A] text-white border-[1px] py-[8px] px-[20px] rounded-full"
            }
            text={t(`lang.ANP`)}
          />
        </div>
      </div>
      {/*  */}
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner size="md" />
        </div>
      ) : (
        <TableComp
          Data={getFieldRep}
          columnsComp={FieldRepColumns(tableDropDown, getFieldRep)}
          pagination
        />
      )}
      <OffCanvasComp
        title={"Field Reps"}
        show={showCavans}
        bodyComponent={
          <AddFieldRep
            edit={edit}
            refetch={refetch}
            setShowCavans={setShowCavans}
            // onNext={()=>{
            //   refetch();
            //   setShowCavans(false)
            // }}
          />
        }
        handleClose={() => {
          setShowCavans(false);
          // navigate(`/order_management`);
        }}
      />
    </div>
  );
};

export default FieldRep;

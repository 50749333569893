import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { SideBarData } from "../Data/SideBar";
import { ImageComponent } from "./FallbackImage";
import { logout } from "../../store/auth";
import { useGetUserProfileQuery } from "../../store/User/userApi";
import { DashBoardApi } from "../../store/DashBoard/DashBoardApi";
import { ProductApi } from "../../store/Product/ProductApi";
import { CustomerApi } from "../../store/Customer/CustomerApi";
import { authApi } from "../../store/auth/authApi";
import { OrderApi } from "../../store/Order/OrderApi";

export default function SideBar({ active, setToggle }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
    console.log(lng, "Lng Aside");
  }, [i18n]);

  const { data: userProfile } = useGetUserProfileQuery();
  const [openDown, setOpenDown] = useState(false);
  const logo =
    userProfile?.data?.company?.logo ===
    "https://suppliers-product-img.s3.amazonaws.com/Chaise%20Lounge.jpg"
      ? "/icon/hetero.jpg"
      : userProfile?.data?.company?.logo;

  return (
    <div className="overflow-y-scroll h-[100vh] check overflow-x-hidden">
      <div
        className="position-relative mb-5 text-center flex justify-center"
        style={{ height: "180px" }}
      >
        <ImageComponent
          width={200}
          height={200}
          className="object-contain"
          src={userProfile?.data?.company?.logo ? logo : "/Images/noUser.jpg"}
        />
      </div>
      <div className="px-3">
        <div>
          {SideBarData(t)?.map(
            ({ name, icon, icon1, path, activeI, subItem, icon3, url }, i) => (
              <div key={i} onClick={() => setToggle(false)}>
                <div
                  className={`ps-4 d-flex items-start gap-3 fw-medium mb-2 py-3 rounded-pill px-3 cursor-pointer ${
                    active === activeI
                      ? "bg-primaryI text-white animate__fadeIn animate__animated"
                      : "blackII"
                  } ${url && "flex-col"}`}
                  onClick={() => {
                    if (!url) navigate(path);
                  }}
                >
                  <div className="d-flex align-items-center gap-3 fw-medium">
                    {active === activeI ? icon : icon1}
                    {name}
                    {icon3 && (
                      <div
                        className="item_container"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenDown(!openDown);
                        }}
                      >
                        {icon3}
                      </div>
                    )}
                  </div>

                  {openDown &&
                    url?.map((item, j) => (
                      <div
                        key={j}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(item.path);
                        }}
                        className={`item_card w-[87%] ml-[2rem] ${
                          active === item.activeI
                            ? "bg-primaryI text-white text-[.9rem] py-[.5rem] px-[.6rem] rounded-full animate__fadeIn animate__animated"
                            : "blackII"
                        }`}
                      >
                        {item.name}
                      </div>
                    ))}
                </div>
              </div>
            )
          )}
        </div>

        {active === 1 && (
          <div className="container mt-[4rem] w-full rounded-[7px] min-h-[20vh] bg-[#000000]/10 flex items-center justify-center text-center flex-col gap-[.5rem] py-[.9rem]">
            <span className="block font-[600] text-[1rem]">
              Join Referral Community
            </span>
            <small className="block text-[#555555] text-[.9rem]">
              Enter your email address associated with your account to
            </small>

            <button className="font-bold w-[7rem] h-[2rem] text-[.9rem] text-[#3DA84A] bg-transparent border-solid border-[1px] border-[#3DA84A] outline-none rounded-full">
              Get Started
            </button>
          </div>
        )}

        <div
          className="d-flex align-items-end mb-[15rem]"
          style={{ height: "20vh" }}
        >
          <div
            onClick={() => {
              dispatch(DashBoardApi.util.resetApiState());
              dispatch(ProductApi.util.resetApiState());
              dispatch(CustomerApi.util.resetApiState());
              dispatch(authApi.util.resetApiState());
              dispatch(OrderApi.util.resetApiState());
              dispatch(logout());
            }}
            className={`ps-5 d-flex align-items-center gap-4 fw-medium mb-2 py-3 rounded-pill pointer ${"animate__fadeIn animate__animated blackII"}`}
          >
            <ImageComponent
              width={26}
              height={26}
              src="/Dashboard/logout.png"
            />
            {t(`lang.LG`)}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import AdminLayout from "../../Layout/AdminLayout";
import StockRequestMain from "../../Components/modules/Stock_Request/StockRequestMain";

const StockRequest = () => {
  return (
    <AdminLayout active={9}>
      <StockRequestMain />
    </AdminLayout>
  );
};

export default StockRequest;

import React from "react";
import InputComp from "../Ui/InputComp";

export default function ListViewInCanvas({
  title,
  ItemName,
  customItem,
  edit,
  customItemI,
  ...props
}) {
  return (
    <div>
      <div className="text-[12px] text-[#A1A1A1] font-medium mb-3">{title}</div>
      {customItem ? (
        <div>{!edit ? customItem : customItemI}</div>
      ) : (
        <div className="text-[14px] text-[#282728] font-medium flex-1 text-wrap">
          {!edit ? ItemName : <InputComp defaultValue={ItemName} {...props} />}
        </div>
      )}
    </div>
  );
}

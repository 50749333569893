import React, { useState, useEffect } from "react";
import { useGetAllReportsQuery } from "../../../../store/Customer/CustomerApi";
import moment from "moment";
import { formatMoney } from "../../../../utils/formatMoney";
import { Spinner } from "react-bootstrap";

const Reports = ({ id }) => {
  const [allReports, setAllReports] = useState([]);

  const {
    data: getAllReports,
    isSuccess,
    isLoading,
  } = useGetAllReportsQuery(id);

  console.log(getAllReports?.data?.reports, "reports");

  // console.log(isSuccess, "success");
  // console.log(isLoading, "LOADING");

  if (isLoading) {
    return (
      <div className="containe">
        <Spinner size="md" />
      </div>
    );
  }

  if (getAllReports?.data?.reports?.length > 0) {
    return getAllReports?.data?.reports
      ?.slice()
      .reverse()
      .map((item) => {
        if (item?.reportType) {
          return (
            <div
              className="item_container flex items-center justify-start mb-[2rem] gap-[1rem]"
              key={item.id}
            >
              {item?.reportType && (
                <div className="image_container w-[3rem] h-[3rem] p-[.7rem] rounded-full bg-[#4F008A]/10 flex items-center justify-center">
                  <img
                    src={`/svgs/${
                      item?.reportType === "debtor"
                        ? "debtors"
                        : item?.reportType === "payment"
                        ? "payment"
                        : item?.reportType === "userActivities"
                        ? "visit"
                        : ""
                    }.svg`}
                    alt=""
                    className="w-[2rem] h-[2rem]"
                  />
                </div>
              )}
              <div className="text_container  w-full flex flex-col items-start justify-between h-full">
                <small className="block text-[.75rem] text-gray-400">
                  {item?.reportType &&
                    moment(item?.createdAt).format("h a - MMMM Do YYYY")}
                </small>
                <span className="block text-[.8rem]">
                  {item?.reportType === "debtor"
                    ? `Payment of ${formatMoney(item?.payment?.amount)} by ${
                        item?.customer?.contactPersonName
                      } of ${item.customer?.businessName} with status of ${
                        item?.paymentStatus
                      }`
                    : item?.reportType === "payment"
                    ? `${item?.paymentMethod} payment made by ${item?.customer?.businessName} at ${item?.customer?.address} with status of ${item?.paymentStatus}`
                    : item?.reportType === "userActivities"
                    ? `${item?.reportMessage}`
                    : null}
                </span>
              </div>
            </div>
          );
        }
      });
  }

  if (getAllReports?.data?.reports?.length === 0) {
    return (
      <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
        <img src="/svgs/noSchedule.svg" alt="" className="w-[5rem] h-[5rem]" />
        <span className="block">No Report</span>
      </div>
    );
  }
  // return (
  //   <section>
  //     {isLoading ? (
  //       <section className="text-center font-serif text-[1.35rem] font-[500] justify-center items-center">
  //         <Spinner size="sm" />
  //       </section>
  //     ) : isSuccess && getAllReports?.data?.reports?.length > 0 ? (
  //       getAllReports?.data?.reports
  //         ?.slice()
  //         .reverse()
  //         .map((item) => {
  //           if (item?.reportType) {
  //             return (
  //               <div
  //                 className="item_container flex items-center justify-start mb-[2rem] gap-[1rem]"
  //                 key={item.id}
  //               >
  //                 {item?.reportType && (
  //                   <div className="image_container w-[3rem] h-[3rem] p-[.7rem] rounded-full bg-[#4F008A]/10 flex items-center justify-center">
  //                     <img
  //                       src={`/svgs/${
  //                         item?.reportType === "debtor"
  //                           ? "debtors"
  //                           : item?.reportType === "payment"
  //                           ? "payment"
  //                           : item?.reportType === "userActivities"
  //                           ? "visit"
  //                           : ""
  //                       }.svg`}
  //                       alt=""
  //                       className="w-[2rem] h-[2rem]"
  //                     />
  //                   </div>
  //                 )}
  //                 <div className="text_container  w-full flex flex-col items-start justify-between h-full">
  //                   <small className="block text-[.75rem] text-gray-400">
  //                     {item?.reportType &&
  //                       moment(item?.createdAt).format("h a - MMMM Do YYYY")}
  //                   </small>
  //                   <span className="block text-[.8rem]">
  //                     {item?.reportType === "debtor"
  //                       ? `Debt Payment of ${formatMoney(
  //                           item?.balanceAmount,
  //                           true,
  //                           ""
  //                         )} by ${item?.customer?.contactPersonName} of ${
  //                           item.customer?.businessName
  //                         } with status of ${item?.paymentStatus}`
  //                       : item?.reportType === "payment"
  //                       ? `${item?.paymentMethod} payment made by ${item?.customer?.businessName} at ${item?.customer?.address} with status of ${item?.paymentStatus}`
  //                       : item?.reportType === "userActivities"
  //                       ? `${item?.reportMessage}`
  //                       : null}
  //                   </span>
  //                 </div>
  //               </div>
  //             );
  //           }
  //         })
  //     ) : (
  //       <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
  //         <img
  //           src="/svgs/noSchedule.svg"
  //           alt=""
  //           className="w-[5rem] h-[5rem]"
  //         />
  //         <span className="block">No Report</span>
  //       </div>
  //     )}
  //   </section>
  // );
};

export default Reports;

import { Dropdown } from "react-bootstrap";

export default function DropDownComp({
  dropDownText,
  dropDownTextClassName,
  options = [],
  row = {},
  // noDivider=true
}) {
  return (
    <Dropdown className={`border-none ${dropDownTextClassName} mb-0 pb-0`}>
      <Dropdown.Toggle variant="" className="border-0 bg-yellow-400 m-0">
        {dropDownText}
      </Dropdown.Toggle>

      {/* <Dropdown.Menu></Dropdown.Menu> */}

      <Dropdown.Menu className="px-3">
        {options?.length > 0 ? (
          options?.map((item, i) => (
            <span key={i}>
              <Dropdown.Item
                className="text-[14px]"
                onClick={() => {
                  item?.action && item?.action(row);
                }}
              >
                {item?.name}
              </Dropdown.Item>
              {options?.length === 0 && (
                <Dropdown.Divider className="bg-[#eeeaf100] border-[0.2px] border-[#EEEAF1] mb-3 opacity-5" />
              )}
            </span>
          ))
        ) : (
          <>
            {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

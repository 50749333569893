import React, { useState } from "react";
import ButtonComp from "../../Ui/Button";
import { t } from "i18next";
import { useUpdateUserPasswordMutation } from "../../../store/User/userApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Password = ({ userData, setUserData }) => {
  const [form, setForm] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const navigate = useNavigate();

  const [updateUserPassword, { isLoading, isSuccess, isError, error }] =
    useUpdateUserPasswordMutation();

  const handleUpdatePassword = async () => {
    if (form.newPassword !== form.confirmNewPassword) {
      toast.error("Passwords don't match");
    } else {
      const value = {
        password: form.newPassword,
      };

      try {
        const res = await updateUserPassword({ body: value });

        if (res?.data?.success) {
          toast.success("Password update successful");
          setUserData({ ...userData, password: form.newPassword });
          setForm({
            newPassword: "",
            confirmNewPassword: "",
          });

          navigate("/login");
        }
        if (isError) {
          toast.error(error?.data?.message || "Failed to update password");
        }
      } catch (error) {
        toast.error(error?.data?.message || "Failed to update password");
      }
    }
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row md:justify-between justify-start items-center">
        <div className="mt-8 w-full">
          <h1 className="text-lg font-bold">Personal Information</h1>
          <p className="md:text-start text-sm">
            Update your password to secure your account
          </p>
        </div>

        <div className="flex items-center md:justify-end justify-start gap-[1rem] w-full">
          <ButtonComp
            text={t(`lang.D`)}
            btnClassName={
              "px-5 border-[1px]  border-[#DE5A5A] py-2 rounded-pill"
            }
          />
          <ButtonComp
            loader={isLoading}
            onClick={handleUpdatePassword}
            text={t(`lang.SC`)}
            btnClassName={
              "bg-[#3DA84A] text-white py-2 md:text-[1rem] text-[.8rem] rounded-pill w-[10rem] w-[8rem]"
            }
          />
        </div>
      </div>

      <section className="md:flex md:mt-10 mb-[5rem]">
        <div className="flex flex-col items-center justify-center   md:justify-start md:w-[50%]">
          <div className="w-full mt-10">
            <div className=" space-y-4 md:space-y-6">
              <form className="space-y-4 md:space-y-6" action="#">
                {/* <div>
                  <label
                    for="email"
                    className="block mb-2 text-sm font-medium text-[#4F008A]"
                  >
                    {t(`lang.CC`)}
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="********"
                    className=" border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mt-4"
                    required=""
                    value={form.currentPassword}
                    onChange={(e) =>
                      setForm({ ...form, currentPassword: e.target.value })
                    }
                  />
                </div> */}
                <div>
                  <label
                    for="password"
                    className="block mb-2 text-sm font-medium text-[#4F008A]"
                  >
                    {t(`lang.NP`)}
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="********"
                    className=" border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mt-4"
                    required=""
                    value={form.newPassword}
                    onChange={(e) =>
                      setForm({ ...form, newPassword: e.target.value })
                    }
                  />
                </div>
                <div>
                  <label
                    for="password"
                    className="block mb-2 text-sm font-medium text-[#4F008A]"
                  >
                    {t(`lang.CNP`)}
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="********"
                    className=" border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mt-4"
                    required=""
                    value={form.confirmNewPassword}
                    onChange={(e) =>
                      setForm({ ...form, confirmNewPassword: e.target.value })
                    }
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="h-[20rem] w-[.01rem] md:block hidden bg-[#4F008A]/10 !ml-[2rem]"></div>

        <div className="md:w-[40%] md:pl-8 w-full"></div>
      </section>
    </div>
  );
};

export default Password;

import React, { useState, useEffect } from "react";
import {
  useGetAllFieldRepActivitiesQuery,
  useGetAllReportsQuery,
  useGetScheduleQuery,
} from "../../../../store/Customer/CustomerApi";
import { currentDate, firstDateOfTheMonth } from "../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { formatMoney } from "../../../../utils/formatMoney";

const Activities = ({ id }) => {
  const [startDate, setStartDate] = useState(firstDateOfTheMonth());
  const [endDate, setEndDate] = useState(currentDate);
  const [allActivities, setAllActivities] = useState([]);
  const [allReports, setAllReports] = useState([]);
  const {
    data: getAllActivities,
    isLoading: activitiesLoading,
    isSuccess: activitiesSuccess,
  } = useGetAllFieldRepActivitiesQuery(id);

  // schedule
  const {
    data: getScheduleData,
    isLoading,
    isSuccess,
    isFetching: getScheduleReFreshing,
    refetch,
  } = useGetScheduleQuery(
    {
      startDate,
      endDate,
    },
    {
      skip: !startDate || !endDate,
      refetchOnMountOrArgChange: false,
    }
  );

  const realData = getScheduleData?.data.filter(
    (item) => item.userId === Number(id)
  );

  // Reports
  const {
    data: getAllReportsData,
    isSuccess: getReportsSuccess,
    isLoading: getReportsIsLoading,
  } = useGetAllReportsQuery(id);

  useEffect(() => {
    if (activitiesSuccess) {
      setAllActivities(getAllActivities?.data?.reports);
    }
    if (getReportsSuccess) {
      setAllReports(getAllReportsData?.data?.reports);
    }
  }, [getAllActivities, getReportsSuccess]);

  console.log(allReports);

  return (
    <div className="schedule">
      <div className="item_container">
        {allReports && (
          <div
            className={`item_title ${
              allReports?.length === 0 ? "hidden" : "block"
            }`}
          >
            <span className="block font-[600] md:text-[1.5rem] text-[1rem] mb-[1.5rem]">
              General Reports
            </span>
          </div>
        )}
        {allReports &&
          allReports
            ?.slice()
            .reverse()
            .filter((item) => item?.reportType) // Filter out items without a reportType
            .map((item) => (
              <div
                className="item_container flex items-center justify-start mb-[2rem] gap-[1rem]"
                key={item.id}
              >
                {item?.reportType && (
                  <div className="image_container w-[3rem] h-[3rem] p-[.7rem] rounded-full bg-[#4F008A]/10 flex items-center justify-center">
                    <img
                      src={`/svgs/${
                        item?.reportType === "debtor"
                          ? "debtors"
                          : item?.reportType === "payment"
                          ? "payment"
                          : item?.reportType === "userActivities" ||
                            item?.reportType === "customerActivities"
                          ? "visit"
                          : ""
                      }.svg`}
                      alt=""
                      className="w-[2rem] h-[2rem]"
                    />
                  </div>
                )}
                <div className="text_container w-full flex flex-col items-start justify-between h-full">
                  <small className="block text-[.75rem] text-gray-400">
                    {item?.reportType &&
                      moment(item?.createdAt).format("h a - MMMM Do YYYY")}
                  </small>
                  <span className="block text-[.8rem]">
                    {item?.reportType === "debtor" ? (
                      <div className="item_container">
                        {item?.paymentStatus === "Part payment" ? (
                          <span className="block text-[.8rem]">
                            {`Outstanding balance of ${formatMoney(
                              item?.balanceAmount
                            )} and part payment of ${formatMoney(
                              item?.amount
                            )} made by ${item?.customer?.businessName}`}
                          </span>
                        ) : (
                          <span className="block text-[.8rem]">
                            {`Debt of ${formatMoney(
                              item?.payment?.amount
                            )} by ${
                              item?.customer?.businessName
                            } with status of ${item?.paymentStatus}`}
                          </span>
                        )}
                      </div>
                    ) : item?.reportType === "payment" ? (
                      <p className="description text-[.8rem] text-[#1c1c1c]">
                        {item?.amount
                          ? `${item?.paymentMethod} payment of ${formatMoney(
                              item?.amount
                            )} made by ${item?.customer?.businessName} ${
                              item?.balanceAmount
                                ? `with balance of ${formatMoney(
                                    item?.balanceAmount
                                  )}`
                                : ""
                            } at ${item?.customer?.address} with status of ${
                              item?.paymentStatus
                            }`
                          : `${item?.paymentMethod} payment made by ${item?.customer?.businessName} at ${item?.customer?.address} with status of ${item?.paymentStatus}`}
                      </p>
                    ) : item?.reportType === "userActivities" ? (
                      `${item?.reportMessage}`
                    ) : item?.reportType === "customerActivities" ? (
                      item?.reportMessage ||
                      `${item?.paymentMethod} of ${formatMoney(
                        item?.amount
                      )} with status of ${item?.paymentStatus}`
                    ) : null}
                  </span>
                </div>
              </div>
            ))}
      </div>
      <div className="item_container">
        <div
          className={`item_title ${
            allActivities?.length === 0 ? "hidden" : "block"
          }`}
        >
          <span className="block font-[600] md:text-[1.5rem] text-[1rem] mb-[1.5rem]">
            Leads Report
          </span>
        </div>
        {activitiesLoading && getReportsIsLoading && isLoading ? (
          <section className="text-center font-serif text-[1.35rem] font-[500] justify-center items-center">
            <Spinner size="sm" />
          </section>
        ) : allActivities?.length === 0 &&
          realData?.length === 0 &&
          allReports?.length === 0 ? (
          <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
            <img
              src="/svgs/noSchedule.svg"
              alt=""
              className="w-[5rem] h-[5rem]"
            />
            <span className="block">No Data Yet</span>
          </div>
        ) : (
          allActivities
            ?.slice()
            .reverse()
            .map((item) => (
              <div
                className="item_container flex items-center justify-start mb-[2rem] gap-[1rem]"
                key={item?.id}
              >
                <div className="image_container w-[3rem] h-[3rem] p-[.7rem] rounded-full bg-[#4F008A]/10 flex items-center justify-center">
                  <img
                    src={`/svgs/visit.svg`}
                    alt=""
                    className="w-[1.5rem] h-[1.5rem]"
                  />
                </div>
                <div className="text_container w-full flex flex-col items-start justify-between h-full">
                  <small className="block text-[.75rem] text-gray-400">
                    {moment(item?.createdAt).format("h a - MMMM Do YYYY")}
                  </small>
                  <span className="block text-[.8rem]">
                    {item?.reportMessage}
                  </span>
                </div>
              </div>
            ))
        )}
      </div>
      {/* Schedule */}
      <div className="item_container">
        {realData && (
          <div
            className={`item_title ${
              realData?.length === 0 ? "hidden" : "block"
            }`}
          >
            <span className="block font-[600] md:text-[1.5rem] text-[1rem] mb-[1.5rem]">
              Payment Report
            </span>
          </div>
        )}
        {realData &&
          realData
            ?.slice()
            .reverse()
            .map((item) => (
              <div
                className="item_container flex items-center justify-start mb-[2rem] gap-[1rem]"
                key={item.id}
              >
                <div className="image_container w-[3rem] h-[3rem] p-[.7rem] rounded-full bg-[#4F008A]/10 flex items-center justify-center">
                  <img
                    src={`/svgs/${
                      item?.visitationReason === "Orders"
                        ? "delivery"
                        : item?.visitationReason === "Payment"
                        ? "payment"
                        : "visit"
                    }.svg`}
                    alt=""
                    className="w-[2rem] h-[2rem]"
                  />
                </div>
                <div className="text_container w-full flex flex-col items-start justify-between h-full">
                  <small className="block text-[.75rem] text-gray-400">
                    {moment(item?.createdAt).format("h a - MMMM Do YYYY")}
                  </small>
                  <span className="block text-[.8rem]">
                    {item?.visitationReason === "Orders"
                      ? `Drug order by ${item?.customer?.contactPersonName} with status of ${item.status}`
                      : item?.visitationReason === "Payment"
                      ? `Payment to ${item?.customer?.contactPersonName} of ${item.customer?.businessName} at ${item?.customer?.address}`
                      : `Lead visit of ${item.customer?.businessName} at ${item?.customer?.address}`}
                  </span>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default Activities;

import { BsThreeDotsVertical } from "react-icons/bs";
import DropDownComp from "../../Ui/DropDown";
import moment from "moment";
import { formatMoney } from "../../../utils/formatMoney";
import Beta from "../../Common/beta";

export const OrderColumns = (tableDropDown, Data) => {
  return [
    {
      name: "Order ID",
      selector: (row) =>
        Data?.length > 0 &&
        Data?.length - Data?.findIndex((item) => item?.id === row?.id),
      width: "70px",
      sortable: true,
    },

    {
      name: "Name",
      selector: (row) => (
        <div className="w-full">
          <div>
            {row?.user?.firstName} {row?.user?.lastName}
          </div>
          <div className="border-[1px] border-[#E5DAEE] ps-[4px] py-1  bg-[#F6F2F9] text-[#444444] rounded-md text-[14px] w-[150px] capitalize">
            {row?.customer?.businessName}
          </div>
        </div>
      ),
      sortable: true,
      width: "200px",
    },

    {
      name: "Address",
      selector: (row) => (
        <div>
          <div>
            {row.customer?.address},{row?.customer?.lga}
          </div>
          <div>{row.customer?.state}</div>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Date",
      selector: (row) => moment(row?.createdAt).format("MMM Do YY"),
      sortable: true,
      width: "150px",
    },
    {
      name: "QTY",
      selector: (row) =>
        row?.orderItems.reduce((sum, { quantity }) => sum + quantity, 0),
      sortable: true,
      // width:'70px'
    },
    {
      name: "Amount",
      selector: (row) =>
        formatMoney(
          row?.orderItems.reduce((sum, { price }) => sum + Number(price), 0),
          false,
          ""
        ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) =>
        row?.status === "APPROVED" ? (
          <div className="border-[#044E54] border-[1px] rounded-full px-[16px] capitalize py-[4px] bg-[#044E54]/10">
            Approved
          </div>
        ) : row?.status === "PENDING" ? (
          <div className="border-[#E9E911] border-[1px] rounded-full px-[16px] capitalize py-[4px] bg-[#f3efe1]">
            Pending
          </div>
        ) : row?.status === "REFUNDED" ? (
          <div className="border-[#E9E911] border-[1px] rounded-full px-[16px] capitalize py-[4px] bg-[#f3efe1]">
            Refunded
          </div>
        ) : (
          <div className="border-[#D63434] border-[1px] rounded-full px-[16px] capitalize py-[4px] bg-[#D63434]/10">
            Canceled
          </div>
        ),
      sortable: true,
      // width:'100px'
    },
    {
      name: (
        <div>
          Due Date
          {/* <Beta position={'right'}/> */}
        </div>
      ),
      selector: (row) => row?.payLaterDate || row.dueDate || "-",
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      // selector: (row) => <DropDownComp dropDownText={<BsThreeDotsVertical color="#7572EA" />}/>,
      cell: (row) => (
        <DropDownComp
          row={row}
          options={tableDropDown}
          dropDownText={<BsThreeDotsVertical color="#7572EA" />}
        />
      ),
      // width:'100px'
    },
  ];
};

export const CategoryData = [
  {
    name: (
      <span className="text-[14px] flex py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#4F008A] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        All
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] pr-[3rem] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Pharmacy Channel
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Wholesales Chan...
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        GPO Channels
      </span>
    ),
  },
];

export const OrderDetails = (Data) => {
  return [
    {
      name: "S/N",
      selector: (row) => Data?.findIndex((item) => item?.id === row?.id) + 1,
      width: "70px",
      // sortable: true,
    },

    {
      name: "Prod. Name",
      selector: (row) => (
        <div className="w-full">
          <div>{row?.product?.productName}</div>
          {/* <div className="border-[1px] border-[#E5DAEE] ps-[4px] py-1  bg-[#F6F2F9] text-[#444444] rounded-md text-[14px] w-[150px] capitalize">{row?.customer?.businessName}</div> */}
        </div>
      ),
      sortable: true,
      // width:'200px'
    },

    {
      name: "QTY",
      selector: (row) => (
        <div>
          {/* <div>{row.customer?.address},{row?.customer?.lga}</div> */}
          <div>{row?.quantity}</div>
        </div>
      ),
      sortable: true,
      // width:'200px'
    },
    {
      name: "Price",
      selector: (row) => formatMoney(row?.price),
      sortable: true,
      // width:'150px'
    },
  ];
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";

export const ProductApi = createApi({
  reducerPath: "ProductApi",
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    getAllProduct: builder.query({
      query: (month) => ({
        url: `/supplier/product`,
        method: "GET",
      }),
    }),
    getCategories: builder.query({
      query: (month) => ({
        url: `/supplier/product/categories`,
        method: "GET",
      }),
    }),
    addProduct: builder.mutation({
      query: (payload) => ({
        url: `/supplier/product`,
        method: "POST",
        body: payload,
      }),
      extraOptions: { contentType: "multipart/form-data" },
      transformResponse: (response) => response.data,
    }),
    addProductCsv: builder.mutation({
      query: (payload) => ({
        url: `/supplier/product/upload-csv`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
    }),
    addDynamicPricing: builder.mutation({
      query: (payload) => ({
        url: `/prices/create`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
    }),
    updateProduct: builder.mutation({
      query: ({ form, id }) => ({
        url: `/supplier/product/${id}`,
        method: "PATCH",
        body: form,
      }),
      invalidatesTags: ["getAllProduct"],
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/supplier/product/${id}`,
        method: "DELETE",
        // body: form,
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetAllProductQuery,
  useAddProductMutation,
  useGetCategoriesQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useAddProductCsvMutation,
  useAddDynamicPricingMutation,
} = ProductApi;

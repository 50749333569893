import React, { useEffect, useState } from "react";
import { TableComp } from "../../Common/TableComp";
import { useNavigate, useParams } from "react-router-dom";
import OffCanvasComp from "../../Ui/OffCanvas";
import ViewProductDetails from "./submodules/ViewProductDetails";
import TopHeader from "../../Common/TopHeader";
import { OrderColumns } from "./Data";
import SubHeader from "../../Common/SubHeader";
import { ImageComponent } from "../../Common/FallbackImage";
import ButtonComp from "../../Ui/Button";
import ModalComp from "../../Ui/ModalComp";
import TextareaComp from "../../Ui/TextArea";
import {
  useAcceptOrderMutation,
  useDeclineOrderMutation,
  useGetAllOrderQuery,
} from "../../../store/Order/OrderApi";
import useFormValidator from "use-form-input-validator";
import { toast } from "react-toastify";
import { SortOrder } from "../../../utils/helper";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

export default function OrderManagementComp() {
  const navigate = useNavigate();
  const { id } = useParams();
  // toast.error('hello')
  const [showCavans, setShowCavans] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [orderList, setOrderList] = useState([]);
  const { t } = useTranslation();

  const {
    data: OrderData,
    isLoading: OrderDataLoader,
    isSuccess: OrderDataSuccess,
    refetch,
  } = useGetAllOrderQuery();

  const [
    declineOrder,
    { isLoading: declineOrderLoader, isSuccess: declineOrderSuccess },
  ] = useDeclineOrderMutation();
  const [
    AcceptOrder,
    { isLoading: acceptOrderLoader, isSuccess: acceptOrderSuccess },
  ] = useAcceptOrderMutation();

  useEffect(() => {
    if (OrderDataSuccess) {
      setOrderList(OrderData?.data?.order);
    }
  }, [OrderData?.data?.order, OrderDataSuccess]);

  // console.log(OrderData,'OrderData')

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isAllFieldsValid()) {
      const payload = {
        orderId: id,
        reason: values?.reason,
      };

      // console.log(payload, "payload");
      declineOrder(payload);
    }
  };
  const handleOrderAccept = (e) => {
    e.preventDefault();
    const payload = {
      orderId: id,
      // reason: values?.reason,
    };
    // console.log(payload, "payload");
    AcceptOrder(payload)
      .unwrap()
      .then((data) => {
        // console.log(data);
        toast.success("Success");
      })
      .catch((err) => {
        console.log(err?.data, "err");
        toast.error(err?.data?.message);
      });
  };

  const { values, errors, updateField, isAllFieldsValid } = useFormValidator({
    reason: {
      checks: "required|reason is required",
      value: "",
      // msg:'reason is required'
    },
  });

  const tableDropDown = [
    {
      name: "View Order Detail",
      action: (row) => {
        console.log(row, "row");
        navigate(`/order_management/${row?.id}`);
        setShowCavans(true);
      },
    },
  ];

  const ModalPage = [
    {
      name: "decline Order",
      component: (
        <DeclineOrderModal
          loader={declineOrderLoader}
          handleSubmit={handleSubmit}
          updateField={updateField}
          errors={errors}
          setShowModal={setShowModal}
        />
      ),
    },
    {
      name: "Accept Order",
      component: (
        <AcceptOrderModal
          loader={acceptOrderLoader}
          handleSubmit={handleOrderAccept}
          updateField={updateField}
          errors={errors}
          setShowModal={setShowModal}
        />
      ),
    },
  ];

  useEffect(() => {
    if (id) {
      setShowCavans(true);
    }
    if (acceptOrderSuccess) {
      setShowCavans(false);
      setShowModal(false);
      refetch();
    }
  }, [id, acceptOrderSuccess]);

  if (OrderDataSuccess)
    // console.log(SortOrder([...OrderData?.data?.order]),'OrderData')
    // console.log([...OrderData?.data?.order]?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)),'OrderData')

    console.log(OrderData?.data?.order, "id");
  return (
    <div className="">
      <TopHeader
        title={t(`lang.OM`)}
        navigate={navigate}
        btnText={"Open Reports"}
        plus
        addBtnAction={() => navigate("/order/sale_orders")}
      />
      <SubHeader
        inputPlaceholder={t(`lang.OMS`)}
        showFilterBtn
        statusAllBtn={false}
        onChange={(e) => setSearchField(e.target.value)}
        CategoryBtn={true}
        sortBtn={true}
      />
      <div className="overflow-hidden">
        {/* <div className="card">
          <div className="card-body"> */}

        <div>
          {OrderDataLoader ? (
            <div className="w-full h-full flex items-center justify-center">
              <Spinner size="md" />
            </div>
          ) : (
            <TableComp
              loader={OrderDataLoader}
              Data={OrderData?.data?.order
                ?.filter(
                  (item) =>
                    item?.user?.firstName
                      .toLowerCase()
                      .match(searchField?.toLowerCase()) ||
                    item?.user?.lastName
                      .toLowerCase()
                      .match(searchField?.toLowerCase()) ||
                    item?.customer?.contactPersonName
                      .toLowerCase()
                      .match(searchField?.toLowerCase()) ||
                    item?.customer?.address
                      .toLowerCase()
                      .match(searchField?.toLowerCase()) ||
                    item?.customer?.businessName
                      .toLowerCase()
                      .match(searchField?.toLowerCase())
                )
                .reverse()}
              columnsComp={OrderColumns(tableDropDown, orderList)}
              pagination
              // sortId={4}
            />
          )}
        </div>
        {/* </div>
        </div> */}
      </div>
      <OffCanvasComp
        title={"Order"}
        show={showCavans}
        id={
          OrderData?.data?.order?.find(
            (item) => Number(item?.id) === Number(id)
          )?.id
        }
        bodyComponent={
          <ViewProductDetails
            handleSubmit={() => setShowModal("decline Order")}
            handleUpdateOrder={() => setShowModal("Accept Order")}
            Data={OrderData?.data?.order?.find(
              (item) => Number(item?.id) === Number(id)
            )}
          />
        }
        handleClose={() => {
          setShowCavans(false);
          navigate(`/order_management`);
        }}
      />
      <ModalComp
        show={showModal}
        bodyComponents={
          ModalPage?.find((item) => item?.name === showModal)?.component
        }
        handleClose={() => setShowModal()}
      />
      {/* <ModalComp show={true} bodyComponents={

        <div>

        </div>
      }/> */}
    </div>
  );
}

const DeclineOrderModal = ({
  handleSubmit,
  updateField,
  errors,
  loader,
  setShowModal,
}) => {
  return (
    <form>
      <div className="flex justify-center mb-[20px]">
        <ImageComponent src="/icon/box.svg" />
      </div>
      <div className="text-center mb-[30px] lg:mb-[62px]">
        <h5 className="font-semibold text-[18px] lg:text-[20px] mb-[20px] mx-[30px] lg:mx-[72px] leading-[32px] ">
          Are You Sure You Want To Decline This Order?
        </h5>
        <div className="mx-[50px]">
          <TextareaComp
            name="reason"
            onChange={updateField}
            error={errors.reason}
            rows={3}
            placeholder={"Add a comment..."}
          />
        </div>
      </div>
      <div className="row  mb-[64px] gap-[15px] justify-center">
        <div className="col-5">
          <ButtonComp
            text={"Cancel"}
            onClick={() => setShowModal()}
            btnClassName={
              "text-[14px] lg:text-[16px] w-full  py-[5px] lg:py-[13px]  px-[20px] lg:px-[34px] border-[0.64px] rounded-full border-[#D63434] text-[#D63434]"
            }
          />
        </div>
        <div className="col-5">
          <ButtonComp
            loader={loader}
            type="submit"
            onClick={handleSubmit}
            btnClassName={
              "text-[14px] lg:text-[16px] w-full py-[5px] lg:py-[13px] px-[25px] lg:px-[47px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
            }
            text={"Proceed"}
          />
        </div>
      </div>
    </form>
  );
};

const AcceptOrderModal = ({
  handleSubmit,
  updateField,
  errors,
  loader,
  setShowModal,
}) => {
  return (
    <form han>
      <div className="flex justify-center mb-[20px]">
        <ImageComponent src="/icon/box1.svg" />
      </div>
      <div className="text-center mb-[30px] lg:mb-[62px]">
        <h5 className="font-semibold text-[18px] lg:text-[20px] mb-[20px] mx-[30px] lg:mx-[72px] leading-[32px] ">
          Order Accepted Successfully
        </h5>
        <p className="text-[14px]">
          Your product has been successfully added and you can easily share the
          details with field reps
        </p>
        {/* <div className="mx-[50px]">
          <TextareaComp
            name="reason"
            onChange={updateField}
            error={errors.reason}
            rows={3}
            placeholder={"Add a comment..."}
          />
        </div> */}
      </div>
      <div className="row  mb-[64px] gap-[15px] justify-center">
        <div className="col-5">
          <ButtonComp
            text={"Cancel"}
            onClick={() => setShowModal()}
            btnClassName={
              "text-[14px] lg:text-[16px] w-full  py-[5px] lg:py-[13px]  px-[20px] lg:px-[34px] border-[0.64px] rounded-full border-[#D63434] text-[#D63434]"
            }
          />
        </div>
        <div className="col-5">
          <ButtonComp
            loader={loader}
            type="submit"
            onClick={handleSubmit}
            btnClassName={
              "text-[14px] lg:text-[16px] w-full py-[5px] lg:py-[13px] px-[25px] lg:px-[47px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
            }
            text={"Proceed"}
          />
        </div>
      </div>
    </form>
  );
};

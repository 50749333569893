import moment from "moment";
import { formatMoney } from "../../../utils/formatMoney";
import DropDownComp from "../../Ui/DropDown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ImageComponent } from "../../Common/FallbackImage";

export const FieldRepColumns = (tableDropDown = {}, Data = []) => {
  return [
    {
      name: "REP ID",
      selector: (row) => (
        <div className="text_container font-[500]">{`#${row.id}`}</div>
      ),
      width: "100px",
      sortable: true,
    },

    {
      name: "Photos",
      selector: (row) => (
        <ImageComponent src="/Images/noImage.png" width={50} height={50} />
      ),

      sortable: true,
      width: "200px",
    },

    {
      name: "Rep Full Name",
      selector: (row) => (
        <div>
          {row?.firstName} {row?.lastName}
        </div>
      ),

      sortable: true,
      width: "200px",
    },
    {
      name: "Date Added",
      selector: (row) => moment(row?.createdAt).format("MMM Do YY"),
      sortable: true,
      width: "150px",
    },

    {
      name: "Customer",
      selector: (row) => row?.totalCustomers || "-",
      sortable: true,
      // width:'70px'
    },

    {
      name: "Availability",
      selector: (row) =>
        // row?.status === "active" ? (
        //   <div className="border-[#044E54] border-[1px] rounded-full px-[16px] capitalize py-[4px] bg-[#044E54]/10">
        //     active
        //   </div>
        // ) : row?.status === "inactive" ? (
        //   <div className="border-[#E9E911] border-[1px] rounded-full px-[16px] capitalize py-[4px] bg-[#f3efe1]">
        //     In-Active
        //   </div>
        // ) : (
        //   <div className="border-[#D63434] border-[1px] rounded-full px-[16px] capitalize py-[4px] bg-[#D63434]/10">
        //     Deactivated
        //   </div>
        // ),

        row?.deactivated === false || row?.deactivated === null ? (
          <div className="border-[#044E54] border-[1px] rounded-full px-[16px] capitalize py-[3px] bg-[#044E54]/10">
            Active
          </div>
        ) : (
          <div className="border-[#D63434] border-[1px] rounded-full px-[16px] capitalize py-[4px] bg-[#D63434]/10">
            Deactivated
          </div>
        ),
      sortable: true,
      width: "200px",
    },

    {
      name: "Last Active",
      selector: (row) =>
        row?.lastRequestTime === null
          ? "-"
          : moment(row?.lastRequestTime).startOf("hour").fromNow(),
      sortable: true,
      width: "200px",
    },

    {
      name: "Action",
      // selector: (row) => <DropDownComp dropDownText={<BsThreeDotsVertical color="#7572EA" />}/>,
      cell: (row) => (
        <DropDownComp
          row={row}
          options={tableDropDown}
          dropDownText={<BsThreeDotsVertical color="#7572EA" />}
        />
      ),
      // width:'100px'
    },
  ];
};

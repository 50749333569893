import React from "react";
import { Offcanvas } from "react-bootstrap";
import ButtonComp from "../../Common/ButtonComp";

const AddCustomer = ({
  openAside,
  setOpenAside,
  currentCustomer,
  setCurrentCustomer,
}) => {
  return (
    <Offcanvas
      show={openAside}
      onHide={() => setOpenAside(false)}
      placement="end"
    >
      <Offcanvas.Header className="mx-2">
        <div className="w-full">
          <div
            className="mt-[10px] flex justify-between items-end w-full cursor-pointer"
            onClick={() => setOpenAside(false)}
          >
            <img src="/icon/close.svg" alt="Close" />
          </div>
          <div className="mt-[30px] flex justify-between items-center w-full">
            <h5 className="mb-0 font-semibold text-right w-full block">
              Customer
            </h5>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="item_container flex items-center justify-center flex-col gap-[1rem] w-full">
          <div className="form-group w-full">
            <label
              htmlFor="contactPersonName"
              className="mb-[.5rem] text-[.9rem] font-[500]"
            >
              Contact Person
            </label>
          </div>
          <div className="form-group w-full">
            <label
              htmlFor="businessName"
              className="mb-[.5rem] text-[.9rem] font-[500]"
            >
              Business Name
            </label>
          </div>

          <div className="form-group w-full">
            <label
              htmlFor="email"
              className="mb-[.5rem] text-[.9rem] font-[500]"
            >
              Email Address
            </label>
          </div>
          <div className="form-group w-full">
            <label
              htmlFor="businessPhoneNumber"
              className="mb-[.5rem] text-[.9rem] font-[500]"
            >
              Phone Number
            </label>
          </div>
          <div className="form-group w-full">
            <label
              htmlFor="address"
              className="mb-[.5rem] text-[.9rem] font-[500]"
            >
              Address
            </label>
          </div>
          <div className="button-container mt-[2rem] w-full">
            <ButtonComp
              text={"Edit Customer"}
              btnClassName={"text-white"}
              wrapper={
                "bg-[#4F008A] w-full rounded-full h-[3rem] flex items-center justify-center"
              }
            />
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddCustomer;

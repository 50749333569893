import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../api/baseUrl";
import { baseQuery } from "../api";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: (body) => ({
        url: "/auth/me",
        method: "GET",
        body,
      }),
    }),
    updateUserProfile: builder.mutation({
      query: ({ body }) => ({
        url: "/company/self/profile",
        method: "PUT",
        body: body,
      }),
    }),
    updateUserCompany: builder.mutation({
      query: ({ body }) => ({
        url: "/company/company/profile",
        method: "PUT",
        body: body,
      }),
    }),
    updateUserPassword: builder.mutation({
      query: ({ body }) => ({
        url: "/auth/reset/logged-in/password",
        method: "PUT",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useUpdateUserCompanyMutation,
  useUpdateUserPasswordMutation,
} = userApi;

import React, { useState } from "react";
import { TableComp } from "../../Common/TableComp";
import { ProductColumns } from "../Data";
import { useNavigate, useParams } from "react-router-dom";
import OffCanvasComp from "../../Ui/OffCanvas";
import ViewProductDetails from "./submodules/ViewProductDetails";
import TopHeader from "../../Common/TopHeader";
import SubHeader from "../../Common/SubHeader";
import {
  useDeleteProductMutation,
  useGetAllProductQuery,
} from "../../../store/Product/ProductApi";
import { useEffect } from "react";
import ModalComp from "../../Ui/ModalComp";
import DeleteModal from "../../Common/DeleteModal";
import { useTranslation } from "react-i18next";
export default function ProductComp() {
  const { t } = useTranslation();

  const [showCavans, setShowCavans] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [sortField, setSortField] = useState(1);
  let { id } = useParams();
  const {
    data: ProductData,
    isLoading: ProductLoader,
    isSuccess: ProductSuccess,
    refetch,
  } = useGetAllProductQuery();

  const [deleteProduct, { isLoading, isSuccess: deleteProductIsSuccess }] =
    useDeleteProductMutation();

  // const {id} =
  const navigate = useNavigate();
  // const []
  const tableDropDown = [
    {
      name: "View Product Details",
      action: (row) => {
        // console.log(row,'row')
        navigate(`/product/${row?.id}`);
        //  setShowCavans(true)
      },
    },
  ];

  useEffect(() => {
    if (id) {
      setShowCavans(true);
    }
  }, [id]);

  const handleDeleteProduct = () => {
    const data = deleteProduct(id);
    // console.log(data,'handleDeleteProduct')
  };

  return (
    <div>
      <TopHeader
        navigate={navigate}
        btnText={t("lang.AP")}
        addBtnAction={() => navigate("/product/add")}
      />
      <SubHeader
        sortField={sortField}
        setSortField={setSortField}
        statusAllBtn
        showFilterBtn
        filterBtn
        sortBtn
        CategoryBtn
        onChange={(e) => setSearchField(e.target.value)}
      />
      <div className="table_component">
        <TableComp
          loader={ProductLoader}
          sortId={sortField}
          Data={ProductData?.data
            ?.filter((item) =>
              item?.productName.toLowerCase().match(searchField)
            )
            .reverse()}
          columnsComp={ProductColumns(
            tableDropDown,
            ProductData?.data?.length > 0
              ? [...ProductData?.data]?.reverse()
              : []
          )}
          pagination
        />
      </div>
      <OffCanvasComp
        show={showCavans}
        title={`
          #${
            ProductData?.data.find((item) => Number(item?.id) === Number(id))
              ?.id
          }
        `}
        bodyComponent={
          <ViewProductDetails
            productId={id}
            handleCancel={(e) => {
              e.preventDefault();
              setDeleteModal(true);
            }}
            onSuccess={() => {
              navigate(`/product/`);
              refetch();
              setShowCavans(false);
            }}
            data={ProductData?.data.find(
              (item) => Number(item?.id) === Number(id)
            )}
          />
        }
        handleClose={() => {
          navigate("/product");
          setShowCavans(null);
        }}
      />
      <ModalComp
        show={deleteModal}
        handleClose={() => setDeleteModal(false)}
        bodyComponents={
          <DeleteModal
            title="Are You Sure You Want To Delete This Product?"
            // setShowModal={()=>setDeleteModal(true)}
            setShowModal={(e) => {
              e.preventDefault();
              setDeleteModal(false);
            }}
            handleSubmit={(e) => {
              e.preventDefault();
              handleDeleteProduct();
            }}
          />
        }
        // handleClose={}
      />
    </div>
  );
}

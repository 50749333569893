import React, { useEffect, useState } from "react";
import { ImageComponent } from "./FallbackImage";
import Skeleton from "react-loading-skeleton";
import { RiMenu2Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { getLanguage, setLanguage, storage } from "../../utils/helper";
import { Nav, NavDropdown } from "react-bootstrap";
import {
  useNotificationListQuery,
  useReadNotificationIIMutation,
  useReadNotificationMutation,
} from "../../store/DashBoard/DashBoardApi";
import moment from "moment";
import { useGetUserProfileQuery } from "../../store/User/userApi";

export default function Header({ setToggle }) {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const { data } = useGetUserProfileQuery();

  const {
    data: notificationMessages,
    isLoading,
    isSuccess,
    refetch,
  } = useNotificationListQuery();

  const [
    readNotification,
    { isLoading: readNotificationLoading, data: notificationRead },
  ] = useReadNotificationMutation();

  const [
    readNotificationII,
    { isLoading: readNotificationLoadingII, data: notificationReadII },
  ] = useReadNotificationIIMutation();

  console.log(
    notificationMessages?.data,
    notificationRead,
    "notificationMessages"
  );

  const language = localStorage.getItem("language");

  console.log(language, "Language");

  useEffect(() => {
    i18n.changeLanguage(getLanguage());
  }, []);

  const handleReadNotification = async (id) => {
    const responses = await readNotificationII({ id: id });
    if (responses?.data) {
      refetch();
    }
  };

  const [toggleTranslateOpen, setToggleTranslateOpen] = useState(false);
  const [selected, setSelected] = useState("");
  return (
    <div className="flex px-2 justify-between items-center">
      <div className="cursor-pointer d-lg-none" onClick={() => setToggle(true)}>
        <RiMenu2Line size={30} />
      </div>
      <div className="py-3 flex items-center justify-end border-bottom w-full">
        <div className="ball_container relative">
          <button
            onClick={() => setToggleTranslateOpen(!toggleTranslateOpen)}
            className="w-[6rem] bg-[#4F008A0D]/5 flex items-center justify-between gap-1 px-3 h-[3.5rem] border-none outline-none rounded-full"
          >
            <div className="item_container capitalize font-semibold flex items-center gap-1">
              {language === "fr" ? (
                <img src="/svgs/france.svg" alt="" />
              ) : (
                <img src="/svgs/england.svg" alt="" />
              )}

              {language || selected}
            </div>
            <div className="icon">
              <img src="/svgs/arrow-down.svg" alt="" />
            </div>
          </button>

          {toggleTranslateOpen && (
            <div className="button_container p-2 bg-white border-solid border-[1px] border-gray-300 z-10 !h-[9rem] rounded-[6px] left-[-14%] absolute top-[120%] w-[8rem]">
              <button
                onClick={() => {
                  changeLanguage("fr");
                  setLanguage("fr");
                  setToggleTranslateOpen(false);
                  setSelected("fr");
                }}
                className={`w-full ${
                  language === "fr" ? "bg-gray-50" : ""
                } flex items-center justify-center gap-2 mb-2 py-3 font-semibold`}
              >
                <img src="/svgs/france.svg" alt="" />
                FR
              </button>
              <button
                onClick={() => {
                  changeLanguage("en");
                  setLanguage("en");
                  setToggleTranslateOpen(false);
                  setSelected("en");
                }}
                className={`${
                  language === "en" ? "bg-gray-50" : ""
                } w-full py-3 font-semibold flex items-center justify-center gap-2`}
              >
                <img src="/svgs/england.svg" alt="" />
                EN
              </button>
            </div>
          )}

          {/* <button
            onClick={() => setToggleTranslateOpen(!toggleTranslateOpen)}
            className="bg-gray md:w-[4rem] md:h-[4rem] h-[3rem] w-[3rem] rounded-full flex items-center justify-center"
          >
            <ImageComponent width={20} height={20} src="/icon/global.png" />
          </button>

          {toggleTranslateOpen && (
            <div className="button_container p-2 bg-white border-solid border-[1px] border-gray-300 z-10 !h-[9rem] rounded-[6px] left-[-120%] absolute top-[120%] w-[14rem]">
              <button
                onClick={() => {
                  changeLanguage("fr");
                  setLanguage("fr");
                }}
                className="w-full bg-gray-50 mb-2 py-3 font-semibold"
              >
                Fr
              </button>
              <button
                onClick={() => {
                  changeLanguage("en");
                  setLanguage("en");
                }}
                className="w-full bg-gray-50 py-3 font-semibold"
              >
                en
              </button>
            </div>
          )} */}
        </div>

        <Nav className="">
          <NavDropdown
            // id="nav-dropdown-dark-example"
            title={
              <div className="bg-gray md:w-[4rem] md:h-[4rem] h-[3rem] w-[3rem] rounded-full flex items-center justify-center">
                <ImageComponent
                  width={20}
                  height={20}
                  src="/icon/notification-bing.png"
                />
              </div>
            }
            // menuVariant="dark"
          >
            <div className="h-[350px] overflow-y-scroll">
              <div className=" w-[350px]   ">
                <div className="flex justify-between mb-[15px] px-[16px]">
                  <div className="font-medium text-[15px]">
                    Notification
                    <span className="ms-2 text-[#9EA0AA]">All</span>
                  </div>
                  <div className="font-medium text-[15px] flex items-center gap-[10px]">
                    Mark all as read
                    <img className="" src="/icon/success-tick.svg" />
                  </div>
                </div>
                {notificationMessages?.data?.notifications?.map((item, i) => (
                  <div
                    className={`mb-2 text-[14px] ${
                      item?.isRead && "bg-[#F5F4F8]"
                    }  py-[8px] px-[16px] cursor-pointer hover:bg-[#F5F4F8]`}
                    onClick={() => {
                      // alert('clicked!')
                      handleReadNotification(item?.id);
                    }}
                  >
                    <div className="mb-1"> {item?.content}</div>
                    <div className="text-[12px] font-medium text-[#A5ACB8]">
                      {moment(item?.createdAt).startOf("day").fromNow()}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </NavDropdown>
        </Nav>
        <div className="bg-gray md:w-[12rem] cursor-pointer md:h-[3.8rem] w-[3.5rem] h-[3.5rem] rounded-full flex items-center justify-center gap-[.8rem]">
          <div className="hidden md:block">
            <div className="blackI font-medium text-[14px]">
              {data?.data?.firstName} {data?.data?.lastName}
            </div>
            <div className="blackIII text-[12px] capitalize">
              {data?.data?.userRole?.split("_").join(" ") || (
                <Skeleton baseColor="#4f008a4d" width={20} />
              )}
            </div>
          </div>
          <ImageComponent
            width={40}
            height={40}
            src={data?.data?.avatar || "/icon/user.png"}
            imageContainer="!rounded-full"
          />
        </div>
      </div>
    </div>
  );
}

import "./App.css";
import {
  Routes,
  Route,
  useNavigate,
  ScrollRestoration,
} from "react-router-dom";
import { ProtectedRoute } from "./Hoc";
import DashBoard from "./Pages/DashBoard";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import { useState } from "react";
import { getToken } from "./utils/helper";
import Product from "./Pages/Product/Product";
import { store } from "../src/store";
import { Provider } from "react-redux";
import AddProduct from "./Pages/Product/AddProduct";
import Customers from "./Pages/Customers/Customers";
import OrderManagement from "./Pages/Order_Management/Order_Management";
import Schedule from "./Pages/Schedule/Schedule";
import ShowMap from "./Pages/Schedule/ShowMap";
import Console from "./Pages/Console/Console";
import VisitTarget from "./Pages/VisitTarget/VisitTarget";
import TopAreas from "./Pages/TopAreas/TopAreas";
import Leads from "./Pages/Leads/Leads";
import SalesOrder from "./Pages/Sales Order/Sales_Order";
import ForgetPassword from "./Pages/ForgetPassword";
import ViewScheduler from "./Pages/Schedule/ViewSchedular";
import Terms from "./Pages/Privacy";
import Verification from "./Pages/Verification";
import RepDetails from "./Components/modules/Admin_Console/RepDetails";
import EditProduct from "./Pages/Product/EditProduct";
import CustomerProfile from "./Pages/Customers/CustomerProfile";
import StockRequest from "./Pages/Stock_request/StockRequest";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

function App() {
  // const [user] =useState(getToken())
  // const navigate =useNavigate();

  // useEffect(() => {
  //   if(user){
  //     navigate('/')
  //   }
  // }, [navigate, user])
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="Signup" element={<SignUp />} />
          <Route path="forget_password" element={<ForgetPassword />} />
          <Route path="reset_password" element={<Verification />} />

          <Route path="privacy" element={<Terms />} />
          <Route element={<ProtectedRoute user={{}} />}>
            <Route path="/" element={<DashBoard />} />
            <Route path="home" element={<Home />} />
            <Route path="product" element={<Product />} />
            <Route path="product/:id" element={<Product />} />
            <Route path="product/add" element={<AddProduct />} />
            <Route path="customer" element={<Customers />} />
            <Route path="customer/:id" element={<CustomerProfile />} />
            <Route path="lead" element={<Leads />} />
            <Route path="lead/:id" element={<Leads />} />
            <Route path="order_management" element={<OrderManagement />} />
            <Route path="order_management/:id" element={<OrderManagement />} />
            <Route path="order/sale_orders" element={<SalesOrder />} />
            <Route path="order/sale_orders/:id" element={<SalesOrder />} />
            <Route path="product/add" element={<AddProduct />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="top_area" element={<TopAreas />} />
            <Route path="visit_target" element={<VisitTarget />} />
            <Route path="schedule/:id/:pagename" element={<Schedule />} />
            <Route path="schedule/field_rep/:id" element={<ViewScheduler />} />
            <Route
              path="schedule/showlocation/:id/:customerId"
              element={<ShowMap />}
            />
            <Route path="stock_request" element={<StockRequest />} />

            <Route path="console" element={<Console />} />
            <Route path="product/:id/edit" element={<EditProduct />} />
            <Route path="field-rep/:id" element={<RepDetails />} />
          </Route>

          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </I18nextProvider>
    </Provider>
  );
}

const Home = () => {
  return <h2>Home (Protected: authenticated user required)</h2>;
};

export default App;

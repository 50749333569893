import React, { useState } from "react";

export function ImageComponent({ width, imageContainer, height, ...props }) {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  return (
    <img
      className={`${loaded ? "" : "blur"} ${imageContainer}`}
      src={props.src}
      onLoad={handleImageLoaded}
      alt=""
      style={{ width: width, height: height }}
      {...props}
    />
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TableComp } from "../../Common/TableComp";
import { useNavigate, useParams } from "react-router-dom";
import OffCanvasComp from "../../Ui/OffCanvas";
import TopHeader from "../../Common/TopHeader";
import SubHeader from "../../Common/SubHeader";
import { CustomerColumns } from "./Data";
import ViewCustomerDetailsComp from "./submodules/ViewProductDetails";
import {
  useGetAllCustomerQuery,
  useGetAllLeadsQuery,
  useUpdateLeadsMutation,
} from "../../../store/Customer/CustomerApi";
import { FiEdit } from "react-icons/fi";
import ButtonComp from "../../Ui/Button";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { Spinner } from "react-bootstrap";
import AddCustomer from "./AddCustomer";
export default function ViewCustomerDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showCavans, setShowCavans] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [LeadData, setLeadData] = useState();
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();

  const {
    data: CustomerData,
    isLoading: CustomerLoader,
    // isSuccess: CustomerSuccess,
  } = useGetAllCustomerQuery();

  const [
    updateLeads,
    { isLoading, isError, data, error, isSuccess: updateLeadIsSuccess },
  ] = useUpdateLeadsMutation();

  // console.log(CustomerData, "CustomerData");
  useEffect(() => {
    if (id) {
      setShowCavans(true);
    }
  }, [id]);

  useEffect(() => {
    if (isError) {
      Array.isArray(error?.data?.message)
        ? error?.data?.message?.map((item) => toast.error(item))
        : toast.error(error?.data?.message);
    }
  }, [error?.data?.message, isError]);

  const handleUpdateLeads = async (data) => {
    // updateLeads
    await updateLeads(LeadData);
    // console.log(dataResult,'handleUpdateLeads123')
    // console.log(LeadData,'handleUpdateLeads')
  };

  // console.log(error,isError,'handleUpdateLeads123')

  const tableDropDown = [
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center">
          <img alt="" src="/svgs/edit.svg" />
          View Customer Profile
        </div>
      ),
      action: (row) => {
        // console.log(row, "row");
        navigate(`/customer/${row?.id}`);
        // setShowCavans(true);
      },
    },
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center">
          <img alt="" src="/icon/refresh.svg" />
          Convert To Customer
        </div>
      ),
      action: (row) => {
        // navigate("/customer/1");
      },
    },
    {
      name: (
        <div className="text-[#D63434] text-[13px] flex gap-2 items-center">
          <img alt="" src="/icon/red-trash.svg" />
          Delete Customer
        </div>
      ),
      action: (row) => {
        // console.log(row, "row");
        // setShowCavans(true);
      },
    },
  ];

  console.log(id, "id");

  let headers = [
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" },
  ];

  // data = [
  //   { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
  //   { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
  //   { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
  // ];

  const [openAside, setOpenAside] = useState(false);

  return (
    <div>
      <TopHeader
        title={`Customers`}
        btnText={"Add Customer"}
        filename={"Customer List"}
        csv
        report
        csvData={CustomerData?.data
          ?.filter(
            (item) =>
              item?.businessName
                .toLowerCase()
                .match(searchField?.toLowerCase()) ||
              item?.businessPhoneNumber
                .toLowerCase()
                .match(searchField?.toLowerCase()) ||
              item?.state.toLowerCase().match(searchField?.toLowerCase())
          )
          .reverse()}
        // addBtnAction={() => setOpenAside(true)}
      />
      <SubHeader
        showFilterBtn
        statusAllBtn
        sortBtn
        inputPlaceholder={"Search Customer, Phone Number,Location"}
        onChange={(e) => setSearchField(e.target.value)}
      />
      <div>
        {CustomerLoader ? (
          <div className="item_container flex items-center justify-center">
            <Spinner size="md" />
          </div>
        ) : (
          <TableComp
            loader={CustomerLoader}
            Data={CustomerData?.data
              ?.filter(
                (item) =>
                  item?.businessName
                    .toLowerCase()
                    .match(searchField?.toLowerCase()) ||
                  item?.businessPhoneNumber
                    .toLowerCase()
                    .match(searchField?.toLowerCase()) ||
                  item?.state.toLowerCase().match(searchField?.toLowerCase())
              )
              .reverse()}
            columnsComp={CustomerColumns(
              tableDropDown,
              CustomerData?.data?.length > 0 &&
                [...CustomerData?.data]?.reverse()
            )}
            pagination
          />
        )}
      </div>
      <OffCanvasComp
        title={"Customer Preview"}
        // ButtonComp={<ButtonComp
        // loader={isLoading}
        // // onClick={()=>edit?handleUpdateLeads():setEdit(true)}
        // btnClassName={'bg-primaryI text-white py-2 px-5 rounded-full'}
        // text={edit?'Update Customer':'Edit Customer'}
        // />}
        show={showCavans}
        bodyComponent={
          <ViewCustomerDetailsComp
            handleSubmit={setLeadData}
            edit={edit}
            data={CustomerData?.data?.find(
              (item) => Number(item?.id) === Number(id)
            )}
          />
        }
        handleClose={() => {
          setShowCavans(false);
          navigate("/customer");
          setEdit(false);
        }}
      />

      {openAside && (
        <AddCustomer openAside={openAside} setOpenAside={setOpenAside} />
      )}
    </div>
  );
}

import DataTable from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import styled from "styled-components";
import { ImageComponent } from "./FallbackImage";
import NoData from "./NoData";

const columns = [
  {
    name: "Product ID",
    selector: (row) => row.title,
    sortable: true,
  },
  {
    name: "Product Name",
    selector: (row) => row.year,
    sortable: true,
  },
  {
    name: "Availability",
    selector: (row) => row.year,
    sortable: true,
  },
  {
    name: "Price (  )",
    selector: (row) => row.year,
    sortable: true,
  },
  {
    name: "Action",
    sortable: true,
    selector: (row) => <BsThreeDotsVertical color="#7572EA" />,
  },
];

export function TableComp({
  columnsComp,
  pagination,
  Data = [],
  sortId = 1,
  loader = true,
}) {
  const hasRows = Data && Data.length > 0;

  // Customize the DataTable conditional rendering
  const conditionalRowStyles = hasRows ? {} : { display: "table-header-group" };

  return (
    <TableCompStyled className="px-2">
      <DataTable
        columns={columnsComp && columnsComp}
        data={Data}
        // noHeader={false}
        pagination={Data?.length > 10 && pagination ? true : false}
        defaultSortFieldId={sortId}
        customStyles={customStyles}
        noHeader={true}
        conditionalRowStyles={conditionalRowStyles}
        noDataComponent={!loader && <NoData />}
      />
    </TableCompStyled>
  );
}
const customStyles = {
  headCells: {
    style: {
      // Increase the width of the header cell to fit the full title
      minWidth: "200px",
    },
  },
};

const TableCompStyled = styled.span`
  padding: 0 5px;
  .rdt_TableHeadRow {
    font-family: "Gilroy-Medium", sans-serif !important;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid rgba(79, 0, 138, 0.1);
  }
  .rdt_TableRow {
    padding-top: 15px;
    padding-bottom: 14px;
    border: none;
    font-weight: 500;
  }
  .rdt_TableRow:nth-child(even) {
    background: rgba(79, 0, 138, 0.04);
  }
`;

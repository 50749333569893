import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteCustomerMutation,
  useGetAllCustomerDebtorReportQuery,
  useGetAllCustomerPaymentQuery,
  useGetAllCustomerQuery,
  useGetAllCustomerReportQuery,
  useGetAllCustomerStockReportQuery,
  useGetSingleCustomerQuery,
} from "../../store/Customer/CustomerApi";
import AdminLayout from "../../Layout/AdminLayout";
import moment from "moment";
import { formatMoney } from "../../utils/formatMoney";
import CustomerActivity from "../../Components/modules/Customer/customerDetails/CustomerActivity";
import CustomerPayment from "../../Components/modules/Customer/customerDetails/CustomerPayment";
import CustomerOrder from "../../Components/modules/Customer/customerDetails/CustomerOrder";
import CustomerDebt from "../../Components/modules/Customer/customerDetails/CustomerDebt";
import CustomerReports from "../../Components/modules/Customer/customerDetails/CustomerReports";
import ModalComp from "../../Components/Ui/ModalComp";
import { Offcanvas, Spinner } from "react-bootstrap";
import ButtonComp from "../../Components/Ui/Button";
import EditCustomer from "./EditCustomer";
import { toast } from "react-toastify";

const CustomerProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  // const { data: customerData, isLoading, isSuccess } = useGetAllCustomerQuery();
  const [currentCustomer, setCurrentCustomer] = useState({});
  const {
    data: getSingleCustomer,
    isLoading: singleLoading,
    isSuccess: singleSuccess,
  } = useGetSingleCustomerQuery(id);

  const [
    deleteCustomer,
    { isLoading: deleteCustomerLoading, isSuccess: deleteCustomerisSuccess },
  ] = useDeleteCustomerMutation();

  useEffect(() => {
    if (singleSuccess) {
      setCurrentCustomer(getSingleCustomer?.data);
    }
  }, [getSingleCustomer]);

  const {
    data: allCustomerReport,
    isLoading: allCustomerReportLoading,
    isSuccess: allCustomerReportSuccess,
  } = useGetAllCustomerReportQuery(id);
  const {
    data: customerPaymentReport,
    isLoading: customerPaymentLoading,
    isSuccess: customerPaymentSuccess,
  } = useGetAllCustomerPaymentQuery(id);
  const {
    data: customerOrder,
    isLoading: customerOrderLoading,
    isSuccess: customerOrderSuccess,
  } = useGetAllCustomerStockReportQuery(id);

  const {
    data: customerDebt,
    isLoading: customerDebtLoading,
    isSuccess: customerDebtSuccess,
  } = useGetAllCustomerDebtorReportQuery(id);

  const [active, setActive] = useState("Activities");

  console.log(customerDebt, "Initial");

  const tabs = [
    {
      name: "Activities",
      element: (
        <CustomerActivity
          data={allCustomerReport}
          isLoading={allCustomerReportLoading}
        />
      ),
      tooltip: "View recent activities",
    },
    {
      name: "Payment",
      element: (
        <CustomerPayment
          data={customerPaymentReport}
          isLoading={customerPaymentLoading}
        />
      ),
      tooltip: "View rep schedule",
    },
    {
      name: "Orders",
      element: (
        <CustomerOrder data={customerOrder} isLoading={customerOrderLoading} />
      ),
      tooltip: "View payment history",
    },
    {
      name: "Debts",
      element: (
        <CustomerDebt data={customerDebt} isLoading={customerDebtLoading} />
      ),
      tooltip: "View potential leads",
    },
    {
      name: "Reports",
      element: (
        <CustomerReports
          id={id}
          activity={allCustomerReport}
          payment={customerPaymentReport}
          order={customerOrder}
          debt={customerDebt}
        />
      ),
      tooltip: "View Field Rep Customers",
    },
  ];

  const [openSide, setOpenSide] = useState(false);

  const handleDeleteCustomer = async () => {
    try {
      const res = await deleteCustomer(id);

      if (res.data.success) {
        toast.success("Action Completed");
        navigate("/customer");
      } else {
        toast.error("Failed to delete customer profile");
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  return (
    <AdminLayout active={4}>
      <div className="flex-section flex items-center justify-between md:flex-row flex-col w-full gap-[1rem]">
        <div className="rep_name flex items-center justify-start gap-[1rem] w-full">
          <button className="icon" onClick={() => navigate(-1)}>
            <img src="/svgs/back.svg" alt="" />
          </button>
          <span className="font-[500] text-[#333333] text-[1.2rem]">
            {currentCustomer?.businessName}
          </span>
        </div>
        <div className="button_section flex items-center md:justify-end justify-start gap-[1rem] w-full">
          <button
            onClick={handleDeleteCustomer}
            className="w-[10rem] text-[#D63434] md:py-[.8rem] py-[.6rem] text-[.89rem] border-solid border-[1px] border-[#D63434]/10 rounded-full bg-white flex items-center justify-center gap-[.74rem]"
          >
            {deleteCustomerLoading && <Spinner size="sm" />}
            Delete Customer
          </button>

          <button
            onClick={() => setOpenSide(true)}
            className="w-[10rem] md:py-[.8rem] py-[.6rem] text-[.89rem] border-none outline-none text-white rounded-full bg-[#4F008A] flex items-center justify-center gap-[.74rem]"
          >
            Edit Customer
          </button>
        </div>
      </div>

      <div className="rep_identity_section mt-[2rem] md:border-solid border-none border-[1px] border-gray-200 w-full rounded-[10px] flex items-start md:p-[1.1rem] justify-center md:flex-row custom:!flex-col md:gap-[1rem] gap-[3rem]">
        <div className="item_container flex w-full items-start justify-start gap-[3rem]">
          <figure className="image_section mt-[.4rem]">
            <img
              src={currentCustomer?.companyLogo || "/svgs/noimage.jpg"}
              alt=""
              className="md:h-[10rem] h-[7rem] rounded-[13px]"
            />
          </figure>
          <div className="user_name">
            <div className="item_container">
              <label
                htmlFor="name"
                className="location mb-[.5rem] font-[500] text-[#555555] text-[.8rem]"
              >
                Contact Person
              </label>
              <span className="name block text-[#000000] font-[500]">
                {currentCustomer?.contactPersonName || "-"}
              </span>
            </div>
            <div className="item_container mt-[2rem]">
              <label
                htmlFor="name"
                className="location mb-[.5rem] font-[500] text-[#555555] text-[.8rem]"
              >
                Business Name
              </label>
              <span className="name block text-[#000000] font-[500]">
                {currentCustomer?.businessName || "-"}
              </span>
            </div>
          </div>
        </div>

        <div className="mega_container flex items-start justify-center gap-[2rem] w-full">
          <div className="second_flex_container w-full">
            <div className="item_container">
              <label
                htmlFor="name"
                className="location mb-[.5rem] font-[500] text-[#555555] text-[.8rem]"
              >
                Date Added
              </label>
              <span className="name block text-[#000000] font-[500]">
                {moment(currentCustomer?.createdAt).format("Do MMMM, YYYY")}
              </span>
            </div>
            {/* <div className="item_container mt-[2rem]">
              <label
                htmlFor="name"
                className="location mb-[.5rem] font-[500] text-[#555555] text-[.8rem]"
              >
                Last Visited
              </label>
              <span className="name block text-[#000000] font-[500]">
                {moment(currentCustomer?.updatedAt).format("Do MMMM, YYYY")}
              </span>
            </div> */}
          </div>
          <div className="third_flex_container w-full">
            <div className="item_container">
              <label
                htmlFor="name"
                className="location mb-[.5rem] font-[500] text-[#555555] text-[.8rem]"
              >
                Phone Number
              </label>
              <span className="name block text-[#000000] font-[500]">
                {currentCustomer?.businessPhoneNumber || "-"}
              </span>
            </div>
            <div className="item_container mt-[2rem]">
              <label
                htmlFor="name"
                className="location mb-[.5rem] font-[500] text-[#555555] text-[.8rem]"
              >
                Email Address
              </label>
              <span className="name block text-[#000000] font-[500]">
                {currentCustomer?.email || "-"}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-container flex mt-[2rem] items-center justify-center md:flex-row flex-col !gap-[2rem] w-full">
        <div className="item_card w-full md:p-[1.5rem] p-[1rem] border-solid border-[1px] border-gray-200 rounded-[7px] flex items-center justify-start gap-[1rem]">
          <div className="svg-container rounded-full bg-[#4F008A]/5 h-[4rem] flex items-center justify-center w-[4rem]">
            <img src="/svgs/sales.svg" alt="" className="w-[2rem] h-[2rem]" />
          </div>
          <div className="text_section flex flex-col gap-[.5rem]">
            <small className="text-[#555555]">Total sales volume</small>
            <span className="block font-[500] text-black">
              {formatMoney(currentCustomer?.totalPayments) || "-"}
            </span>
          </div>
        </div>
        <div className="item_card w-full md:p-[1.5rem] p-[1rem] border-solid border-[1px] border-gray-200 rounded-[7px] flex items-center justify-start gap-[1rem]">
          <div className="item_container">
            <div className="svg-container rounded-full bg-[#4F008A]/5 h-[4rem] flex items-center justify-center w-[4rem]">
              <img
                src="/svgs/address.svg"
                alt=""
                className="w-[2rem] h-[2rem]"
              />
            </div>
          </div>
          <div className="text_section flex flex-col gap-[.5rem]">
            <small className="text-[#555555]">Address</small>
            <p className="block line-clamp-1 text-[.9rem] font-[500] text-black">
              {currentCustomer?.address || "-"}
            </p>
          </div>
        </div>
      </div>

      <div className="tabs_section mt-[3rem]">
        <div className="nav_container w-full bg-white after:block after:w-full after:h-[.1rem] after:bg-[#4F008A]/10 mb-[1.9rem]">
          <div className="nav_section flex items-center justify-start gap-[2rem] w-full overflow-x-scroll no-scrollbar md:pr-0 pr-[2rem]">
            {tabs?.map((item) => (
              <div className="nav_pills" key={item.name}>
                <button
                  onClick={() => setActive(item.name)}
                  className={`text-[.9rem] pb-[1rem] ${
                    active === item.name
                      ? "text-[#4F008A] font-[500] border-b-[1px] border-[#4F008A]"
                      : " text-[#555555]"
                  }`}
                >
                  {item?.name}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pages_section pb-[6rem]">
        {tabs.map((item) => {
          if (active === item.name) {
            return item.element;
          }
        })}
      </div>

      {openSide && (
        <EditCustomer
          currentCustomer={currentCustomer}
          openSide={openSide}
          setOpenSide={setOpenSide}
          setCurrentCustomer={setCurrentCustomer}
          id={id}
        />
      )}
    </AdminLayout>
  );
};

export default CustomerProfile;

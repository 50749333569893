import React from "react";
import { useGetStockReportQuery } from "../../../../store/Customer/CustomerApi";
import { TableComp } from "../../../Common/TableComp";
import { Spinner } from "react-bootstrap";

const AllRequests = () => {
  const { data, isLoading, isSuccess } = useGetStockReportQuery("17");

  if (isLoading) {
    return (
      <div className="item_container flex items-center justify-center">
        <Spinner size="md" />
      </div>
    );
  }

  return (
    <div>
      {data?.data?.reports?.length === 0 ? (
        <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
          <img
            src="/svgs/noSchedule.svg"
            alt=""
            className="w-[5rem] h-[5rem]"
          />
          <span className="block">No Data</span>
        </div>
      ) : (
        <TableComp
          Data={[...data?.data?.reports]?.slice().reverse()}
          columnsComp={OrderColumnsIII([...data?.data?.reports]?.reverse())}
          pagination
          sortId={0}
        />
      )}
    </div>
  );
};

export default AllRequests;
